import { useContext, useEffect, useState } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	CircularProgress,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CompanyEditContext';
import { useParams } from 'react-router-dom';
import { getCompanyId } from '../../../../apps/user-management/users-list/core/_requests';
import { isValidFieldRequired, setValueOrDefault } from '../../../../../util/Index';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
const documentTypes = ['CC', 'CE', 'PEP', 'PA', 'TI', 'PT'];
const ddltipo = [
	{ id: '23', name: 'Procesador de texto (Ejemplo: Word)' },
	{ id: '24', name: 'Hoja de cálculo (Ejemplo: Excel)' },
	{ id: '25', name: 'Multimedia (Ejemplo: PowerPoint)' },
	{ id: '26', name: 'Otros' },
];
const ddlTipoDocument = [
	{ id: 'CS', name: 'Contrato social' },
	{ id: 'ES', name: 'Estatuto social' },
	{ id: 'CRE', name: 'Certificado de registro empresarial' },
	{ id: 'CNPJ', name: 'CNPJ' },
];

const ddlNaturalezaEmpresa = [
	{ id: '1', name: 'Mixta' },
	{ id: '2', name: 'Privada' },
	{ id: '3', name: 'Pública' },
];
const ddlTipoEmpresa = [
	{ id: '001', name: 'EMPRESA ASOCIATIVA DE TRABAJO' },
	{ id: '002', name: 'EMPRESA UNIPERSONAL' },
	{ id: '003', name: 'PERSONA NATURAL COMERCIANTE' },
	{ id: '004', name: 'SOCIEDAD ANÓNIMA' },
	{ id: '005', name: 'SOCIEDAD COLECTIVA' },
	{ id: '006', name: 'SOCIEDAD EN COMANDITA POR ACCIONES' },
	{ id: '007', name: 'SOCIEDAD EN COMANDITA SIMPLE' },
	{ id: '008', name: 'SOCIEDAD LIMITADA' },
	{ id: '009', name: 'SOCIEDADES AGRARIAS DE TRANSFORMACIÓN' },
	{ id: '010', name: 'SOCIEDADES POR ACCIONES SIMPLIFICADAS' },
	{ id: '011', name: 'OTRO' },
];
const ddlTamanioEmpre = [
	{ id: '5', name: 'Unipersonal' },
	{ id: '1', name: 'Microempresa - Entre 2 y 10 trabajadores' },
	{ id: '2', name: 'Pequeña empresa - Entre 11 y 50 trabajadores' },
	{ id: '3', name: 'Mediana empresa - Entre 51 y 200 trabajadores' },
	{ id: '4', name: 'Grande  - Mayor a 200 trabajadores' },
];
export const Step1 = () => {
	const { t } = useTranslation();
	const {
		setStep,
		userData,
		setUserData,
		firstUser,
		setFirstUser,
		firstCompany,
		setFirstCompany,
		loadedData,
		setLoadedData,
	} = useContext(multiStepContext);
	const [documentType, setDocumentType] = useState('');
	const { id } = useParams();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	console.log(firstCompany);

	const [loading, setLoading] = useState(false);

	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const handleChange = (event: SelectChangeEvent) => {
		setDocumentType(event.target.value);
	};
	useEffect(() => {
		if (!loadedData as boolean) getCompany();
		else validationSchemaByTpDoc(firstCompany.company['tipo_documento']);
	}, []);

	const getCompany = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			try {
				setLoading(true);
				const response = await getCompanyId(id);
				setUserData(response.data);
				setFirstCompany();
				const { EmpresaComplementos, EmpresaArcchivos, EmpresaVagas, Users, ...data } =
					response.data;
				validationSchemaByTpDoc(response.data?.tipo_documento || '');
				setFirstCompany({
					...firstCompany,
					company: data,
				});
				console.log('response', response.data);
				if (
					EmpresaComplementos !== null &&
					EmpresaComplementos !== undefined &&
					(EmpresaComplementos as Array<any>).length > 0
				) {
					(EmpresaComplementos as Array<any>).forEach((e) => {
						let x = {
							tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
							complemento: setValueOrDefault(e['complemento'], 'string'),
						};
						firstCompany['complemento'].push(x);
					});
				}
			} catch (error: any) {
				console.error('error', error);
			} finally {
				setLoading(false);
				setLoadedData(true);
			}
		}
	};

	function goBack() {
		window.history.back();
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleNext = () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(firstCompany.company['numero_documento']))
			errors.push({ field: 'Número de documento', error: 'Campo requerido' });
		else {
			if (validLengthDocumentField(firstCompany.company['numero_documento']) !== '')
				errors.push({
					field: 'numero_documento',
					error: validLengthDocumentField(firstCompany.company['numero_documento']),
				});
		}

		if (!isValidFieldRequired(firstCompany.company['tipo_documento']))
			errors.push({ field: 'Tipo de documento', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['razon_social']))
			errors.push({ field: 'Razón social', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['naturaleza']))
			errors.push({ field: 'Naturaleza', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['tipo']))
			errors.push({ field: 'Tipo', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['Tamano_empleados']))
			errors.push({ field: 'Tamaño por número de empleados', error: 'Campo requerido' });

		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\n ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${
						t(x.error)
					}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		}

		setStep(2);
	};

	//validation for company document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				break;
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				break;
			case 'NIT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(9);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};

	//validation for company document
	const validLengthDocumentField = (document: string) => {
		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener al menos')} ${minDocumentFieldLenght} ${t('dígitos')}`;

		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener')} ${maxDocumentFieldLenght} ${t('o menos dígitos')}`;

		if (documentTypeField === 'text')
			if (!document.match(/^[A-Za-z0-9]*$/)) return `${t('Deben ser solo números y letras')}`;

		if (documentTypeField === 'number')
			if (!document.match(/^[0-9]+$/)) return `${t('Deben ser solo números')}`;

		return '';
	};

	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
						{t('Información del empleador')}
					</Typography>
					<Grid container direction='row' justifyContent='space-between' width={'100%'}>
						<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(firstCompany.company['tipo_documento'])}
							>
								<InputLabel id='tipo_documento'>{t('Tipo de documento')}</InputLabel>
								<Select
									required
									labelId='tipo_documento'
									id='tipo_documento'
									label={t('Tipo de documento')}
									value={firstCompany.company['tipo_documento']}
									onChange={(e) => {
										firstCompany.company['numero_documento'] = '';
										validationSchemaByTpDoc(e.target.value);
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, tipo_documento: e.target.value },
										});
									}}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlTipoDocument.map(({ id, name }, index) => (
										<MenuItem key={index} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(firstCompany.company['tipo_documento']) && (
									<FormHelperText>
										{!isValidFieldRequired(firstCompany.company['tipo_documento'])
											? t('Campo requerido')
											: ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								id='numero_documento'
								name='numero_documento'
								label={t('Número de documento')}
								placeholder={t('Ingrese un número')}
								value={firstCompany.company['numero_documento']}
								onChange={(e) => {
									if (e.target.value.length <= maxDocumentFieldLenght)
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, numero_documento: e.target.value },
										});
								}}
								type={documentTypeField}
								size='medium'
								inputProps={{
									maxLength: maxDocumentFieldLenght,
									minLength: minDocumentFieldLenght,
								}}
								error={
									!isValidFieldRequired(firstCompany.company['numero_documento']) ||
									validLengthDocumentField(firstCompany.company['numero_documento']) !== ''
								}
								helperText={
									!isValidFieldRequired(firstCompany.company['tipo_documento'])
										? t('Primero seleccione el tipo de documento')
										: !isValidFieldRequired(firstCompany.company['numero_documento'])
										? t('Campo requerido')
										: validLengthDocumentField(firstCompany.company['numero_documento']) !== ''
										? validLengthDocumentField(firstCompany.company['numero_documento'])
										: ''
								}
								disabled={!isValidFieldRequired(firstCompany.company['tipo_documento'])}
							/>
						</Grid>
						{/* <Grid xs={3} mb={5}> */}
						{/*
          <FormControl sx={{ minWidth: '100%' }} size='medium'>
            <InputLabel id="sector">Sector</InputLabel>
            <Select
            labelId="sector"
            id="sector"
            label="Sector"
            value={userData['sector']}
            onChange={(e) => setUserData({ ...userData, "sector": e.target.value })}
            >
           {ddltipo.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>   
           */}
						{/* </Grid> */}
						<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								id='razon_social'
								name='razon_social'
								label={t('Razón social')}
								placeholder={t('Ingrese la razón social')}
								value={firstCompany.company['razon_social']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, razon_social: e.target.value },
									})
								}
								size='medium'
								error={!isValidFieldRequired(firstCompany.company['razon_social'])}
								helperText={
									!isValidFieldRequired(firstCompany.company['razon_social'])
										? t('Campo requerido')
										: ''
								}
							/>
						</Grid>
						<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(firstCompany.company['naturaleza'])}
							>
								<InputLabel id='naturaleza'>{t('Naturaleza')}</InputLabel>
								<Select
									labelId='naturaleza'
									id='naturaleza'
									label={t('Naturaleza')}
									value={firstCompany.company['naturaleza']}
									onChange={(e) =>
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, naturaleza: e.target.value },
										})
									}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlNaturalezaEmpresa.map(({ id, name }, index) => (
										<MenuItem key={index} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(firstCompany.company['naturaleza']) && (
									<FormHelperText>
										{!isValidFieldRequired(firstCompany.company['naturaleza'])
											? t('Campo requerido')
											: ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{/* <Grid xs={3} mb={5} /> */}
						<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(firstCompany.company['tipo'])}
							>
								<InputLabel id='tipo'>{t('Tipo')}</InputLabel>
								<Select
									labelId='tipo'
									id='tipo'
									label={t('Tipo')}
									value={firstCompany.company['tipo']}
									onChange={(e) =>
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, tipo: e.target.value },
										})
									}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlTipoEmpresa.map(({ id, name }, index) => (
										<MenuItem key={index} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(firstCompany.company['tipo']) && (
									<FormHelperText>
										{!isValidFieldRequired(firstCompany.company['tipo']) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} sm={6} mb={5} item={true}>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(firstCompany.company['Tamano_empleados'])}
							>
								<InputLabel id='Tamano_empleados'>{t('Tamaño por número de empleados')}</InputLabel>
								<Select
									labelId='Tamano_empleados'
									id='Tamano_empleados'
									label={t('Tamaño por número de empleados')}
									value={firstCompany.company['Tamano_empleados']}
									onChange={(e) =>
										setFirstCompany({
											...firstCompany,
											company: { ...firstCompany.company, Tamano_empleados: e.target.value },
										})
									}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlTamanioEmpre.map(({ id, name }, index) => (
										<MenuItem key={index} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(firstCompany.company['Tamano_empleados']) && (
									<FormHelperText>
										{!isValidFieldRequired(firstCompany.company['Tamano_empleados'])
											? t('Campo requerido')
											: ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={() => goBack()}
						>
							{t('Atrás')}
						</Button>
						{/*<Box sx={{ flex: '1 1 auto' }} />*/}
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90 }}
							onClick={handleNext}
						>
							{t('Siguiente')}
						</Button>
					</Box>
				</>
			)}

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
