import axios, { AxiosResponse } from 'axios';
import { ID, Response } from '../../../../../../_metronic/helpers';
import { User, UsersQueryResponse } from './_models';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_URL_OF = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;
const GET_USERS_URL = `${API_URL}/users/query`;
const GET_USERS = `${API_URL_OF}/users/`;
const PUT_ACTIVE_USER = `${API_URL_OF}/activate_user/`;
const PUT_DESACTIVE_USER = `${API_URL_OF}/deactivate_user/`;
const GET_USERCOMPLEMETO = `${API_URL_OF}/user_complement`;
const GET_COMPANIES = `${API_URL_OF}/all_companies/`;
const GET_ALL_ACTIVE_COMPANIES = `${API_URL_OF}/active_companies`;
const GET_COMPANIES_COUNSELOR = `${API_URL_OF}/listEmpresasConselheiro`;
const GET_COMPANY = `${API_URL_OF}/company_by_id/`;
const POST_COMPANY = `${API_URL_OF}/new_company/`;
const POST_UPDATECOMPANY = `${API_URL_OF}/company_update/`;
const GET_USER_BY_ID = `${API_URL_OF}/user_id`;
const GET_VACANCY = `${API_URL_OF}/company_vacancy/`;
const GET_VACANCYS = `${API_URL_OF}/empresa_vagas/`;
const POST_VACANCY = `${API_URL_OF}/company_vacancy`;
const DELETE_VACANCY = `${API_URL_OF}/vacancy`;
const PUT_COMPANY_ID = `${API_URL_OF}/empresaId`;
const POST_FEEDBACK_CANDIDATE = `${API_URL_OF}/feedback_candidate/`;
const POST_EMPRESA_CANDIDATE = `${API_URL_OF}/company_candidate/`;
const PUT_STATUS_EMPRESA = `${API_URL_OF}/update_status_company/`;
const PUT_ACTIVE_EMPRESA = `${API_URL_OF}/activate_company/`;
const DELETE_EMPRESA = `${API_URL_OF}/delete_company`;
const PUT_DESACTIVE_EMPRESA = `${API_URL_OF}/deactivate_company/`;
const GET_VAGACURRICULOID = `${API_URL_OF}/vagaCurriculoId`;
const GET_VAGACOUNTCURRICULOALL = `${API_URL_OF}/all_company_vacancy`;
const GET_VAGAEMPRESACURRICULOALL = `${API_URL_OF}/company_vacancies_cv`;
const GET_AGENDAVAGACURRICULOALL = `${API_URL_OF}/agendaVagaCurriculoAll`;
const GET_AGENDACURRICULOBYID = `${API_URL_OF}/agendaCurriculoById`;
const GET_VAGAPOSTULACURRICULOALL = `${API_URL_OF}/company_vacancies_cv`;
const POST_VACANCY_REGISTER = `${API_URL_OF}/curriculo_vacancy`;
const GET_CURRICULOBYID = `${API_URL_OF}/curriculo_by_id`;
const GET_LOCALIDAD = `${API_URL_OF}/localidad`;
const GET_CURRICULOVAGABYID = `${API_URL_OF}/curriculoVagaByid`;
const GET_VAGASPOSTULADAS = `${API_URL_OF}/applied_jobs`;
const GET_SEARCH_VACANTES = `${API_URL_OF}/search_vacancy`;
const GET_VAGACURRICULOBYID = `${API_URL_OF}/vagaCurriculoByid`;
const GET_CURRICULO = `${API_URL_OF}/curriculo`;
const GET_CURRICULOALL = `${API_URL_OF}/curriculoAll/`;
const GET_USERCURRICULOALL = `${API_URL_OF}/allUserCurriculo/`;
const GET_CURRICULOS = `${API_URL_OF}/curriculos/`;
const POST_CURRICULO = `${API_URL_OF}/curriculo/`;
const PUT_VACANCY = `${API_URL_OF}/company_vacancy`;
const CREATE_USER = `${API_URL_OF}/add_user`;
const UPDATE_USER = `${API_URL_OF}/user`;
const GET_POSITIONS = `${API_URL_OF}/positions`;
const GET_ALL_MEET_CONSEJERO = `${API_URL_OF}/agenda_couselor`;
const GET_ALL_MEET_CANDIDATE = `${API_URL_OF}/agenda_candidate`;
const AGENDA_VERIFY_DATE = `${API_URL_OF}/verify_date`;
const AGENDA_RANDOM_DATE = `${API_URL_OF}/random_date`;
const AGENDA_LIST_COUSELOR = `${API_URL_OF}/list_couselor`;
const AGENDA_CREATE = `${API_URL_OF}/agenda_create`;
const AGENDA_DELETE = `${API_URL_OF}/agenda_delete`;
const UPDATE_VACANCY_STATUS = `${API_URL_OF}/vacancy_status`;
const DOCUMENTS_ZIP = `${API_URL_OF}/download_zip`
const GET_CONTRACT_TYPES = `${API_URL_OF}/contract_types`;
const GET_HIRING_STATUS = `${API_URL_OF}/hiring_status`;

const getUsers = async (query: string): Promise<any> => {
	console.log(`${GET_USERS}?${query}`);
	return axios.get(`${GET_USERS}?${query}`).then((d: any) => d);
};

const getCompanies = async (query?: any): Promise<any> => {
	console.log(`${GET_COMPANIES}?${query}`);
	const d = await axios.get(`${GET_COMPANIES}?${query}`);
	return d;
};

const getAllActiveCompanies = async (): Promise<any> => {
	console.log(`${GET_ALL_ACTIVE_COMPANIES}`);
	const d = await axios.get(`${GET_ALL_ACTIVE_COMPANIES}`);
	return d;
};

const getCompaniesCounselor = async (): Promise<any> => {
	console.log(`${GET_COMPANIES_COUNSELOR}`);
	const d = await axios.get(`${GET_COMPANIES_COUNSELOR}`);
	return d;
};

const getVagaCurriculoById = (query: string): Promise<any> => {
	console.log(`${GET_VAGACURRICULOBYID}?${query}`);
	return axios.get(`${GET_VAGACURRICULOBYID}?${query}`).then((d: any) => d);
};

const getVagaCurriculoId = (query: string): Promise<any> => {
	console.log(`${GET_VAGACURRICULOID}?${query}`);
	return axios.get(`${GET_VAGACURRICULOID}?${query}`).then((d: any) => d);
};

const getVagaCountCurriculoAll = (query: string): Promise<any> => {
	console.log(`${GET_VAGACOUNTCURRICULOALL}?${query}`);
	return axios.get(`${GET_VAGACOUNTCURRICULOALL}?${query}`).then((d: any) => d);
};

const getVagaEmpresaCurriculoAll = (query: string): Promise<any> => {
	console.log(`${GET_VAGAEMPRESACURRICULOALL}?${query}`);
	return axios.get(`${GET_VAGAEMPRESACURRICULOALL}?${query}`).then((d: any) => d);
}; 

const getAgendaVagaCurriculoAll = (query: string): Promise<any> => {
	console.log(`${GET_AGENDAVAGACURRICULOALL}?${query}`);
	return axios.get(`${GET_AGENDAVAGACURRICULOALL}?${query}`).then((d: any) => d);
}; 
const getAgendaCurriculoById = (query: string): Promise<any> => {
	console.log(`${GET_AGENDACURRICULOBYID}?${query}`);
	return axios.get(`${GET_AGENDACURRICULOBYID}?${query}`).then((d: any) => d);
}; 

const getVagaPostulaCurriculoAll = (query: string): Promise<any> => {
	console.log(`${GET_VAGAPOSTULACURRICULOALL}?${query}`);
	return axios.get(`${GET_VAGAPOSTULACURRICULOALL}?${query}`).then((d: any) => d);
};

const getUsersCurriculo = (query: string): Promise<UsersQueryResponse> => {
	return axios
		.get(`${GET_USERS_URL}?${query}`)
		.then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const getUserById = (id: ID): Promise<User | undefined> => {
	return axios
		.get(`${USER_URL}/${id}`)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const getUserDataById = (query: string): Promise<any> => {
	console.log(`${GET_USER_BY_ID}?${query}`);
	return axios.get(`${GET_USER_BY_ID}?${query}`).then((d: any) => d);
};

const deleteUserById = (query: string): Promise<any> => {
	console.log(`${GET_USER_BY_ID}?${query}`);
	return axios.delete(`${GET_USER_BY_ID}?${query}`).then((d: any) => d);
};

const deleteVacancyById = (query: any): Promise<any> => {
	console.log(`${DELETE_VACANCY}`, query, 123);
	return axios.delete(`${DELETE_VACANCY}`, { data: query }).then((d: any) => d);
};

const updateStatusUserToActive = (query: string): Promise<any> => {
	console.log(`${PUT_ACTIVE_USER}?${query}`);
	return axios.put(`${PUT_ACTIVE_USER}?${query}`).then((d: any) => d);
};

const updateStatusUserToDesactive = (query: string): Promise<any> => {
	console.log(`${PUT_DESACTIVE_USER}?${query}`);
	return axios.put(`${PUT_DESACTIVE_USER}?${query}`).then((d: any) => d);
};
const deleteEmpresaById = (query: string): Promise<any> => {
	console.log(`${DELETE_EMPRESA}?${query}`);
	return axios.delete(`${DELETE_EMPRESA}?${query}`).then((d: any) => d);
};
const updateStatusEmpresa = (data: any): Promise<any> => {
	console.log(`${PUT_STATUS_EMPRESA}, ${data}`);
	return axios.put(PUT_STATUS_EMPRESA, data).then((d: any) => d);
};

const emailCandidateEmpresa = (data: any): Promise<any> => {
	console.log(`${POST_EMPRESA_CANDIDATE}, ${data}`);
	return axios.post(POST_EMPRESA_CANDIDATE, data).then((d: any) => d);
};
const feedbackCandidate = (data: any): Promise<any> => {
	console.log(`${POST_FEEDBACK_CANDIDATE}, ${data}`);
	return axios.post(POST_FEEDBACK_CANDIDATE, data).then((d: any) => d);
};

const updateEmpresaToActive = (query: string): Promise<any> => {
	console.log(`${PUT_ACTIVE_EMPRESA}?${query}`);
	return axios.put(`${PUT_ACTIVE_EMPRESA}?${query}`).then((d: any) => d);
};

const updateEmpresaToDesactive = (query: string): Promise<any> => {
	console.log(`${PUT_DESACTIVE_EMPRESA}?${query}`);
	return axios.put(`${PUT_DESACTIVE_EMPRESA}?${query}`).then((d: any) => d);
};

const createUser = (user: User): Promise<User | undefined> => {
	return axios
		.put(USER_URL, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const createUserPost = (user: any): Promise<any> => {
	return axios.post(CREATE_USER, user).then((d: any) => d);
};

const updateUserPut = (user: any): Promise<any> => {
	return axios.put(UPDATE_USER, user).then((d: any) => d);
};
const updateUserComplementoPut = async (user: any): Promise<any> => {
	return axios.put(GET_USERCOMPLEMETO, user).then((d: any) => d);
};

const updateUser = (user: User): Promise<User | undefined> => {
	return axios
		.post(`${USER_URL}/${user.id}`, user)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
};

const updateVacancy = (data: any): Promise<any> => {
	return axios.put(`${PUT_VACANCY}`, data).then((d: any) => d);
};

const deleteUser = (userId: ID): Promise<void> => {
	return axios.delete(`${USER_URL}/${userId}`).then(() => { });
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
	const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
	return axios.all(requests).then(() => { });
};
const getUserCompelmentoId = (id: ID): Promise<any> => {
	return axios.get(`${GET_USERCOMPLEMETO}?id=${id}`).then((d: any) => d);
};
const updateCompany = async (company: any): Promise<void> => {
	const response = await axios.post(`${POST_UPDATECOMPANY}`, company);
	return response.data;
};
const createCompany = async (company: any): Promise<any> => {
	const response = await axios.post(`${POST_COMPANY}`, company);
	return response.data;
};
const createCurriculo = (curriculo: any): Promise<void> => {
	return axios.post(`${POST_CURRICULO}`, curriculo).then((response: any) => response.data);
};
const getCurriculos = (query: string): Promise<any> => {
	console.log(`${GET_CURRICULOS}?${query}`);
	return axios.get(`${GET_CURRICULOS}?${query}`).then((d: any) => d);
};
const getCurriculoAll = (query: string): Promise<any> => {
	console.log(`${GET_CURRICULOALL}?${query}`);
	return axios.get(`${GET_CURRICULOALL}?${query}`).then((d: any) => d);
};

const getUserCurriculoAll = async (query: string): Promise<any> => {
	console.log(`${GET_USERCURRICULOALL}?${query}`);
	return axios.get(`${GET_USERCURRICULOALL}?${query}`).then((d: any) => d);
};

const getCurriculoId = (id: ID): Promise<any> => {
	return axios.get(`${GET_CURRICULO}?id=${id}`).then((d: any) => d);
};

const getCurriculoById = (query: string): Promise<any> => {
	return axios.get(`${GET_CURRICULOBYID}?${query}`).then((d: any) => d);
};
const getLocalidad = (): Promise<any> => {
	return axios.get(`${GET_LOCALIDAD}`).then((d: any) => d);
};
const getVacancys = (query: string): Promise<any> => {
	console.log(`${GET_VACANCYS}?${query}`);
	return axios.get(`${GET_VACANCYS}?${query}`).then((d: any) => d);
};
const getVacancyId = (id: ID): Promise<any> => {
	return axios.get(`${GET_VACANCY}?id=${id}`).then((d: any) => d);
};
const getCompanyId = (id: ID): Promise<any> => {
	return axios.get(`${GET_COMPANY}?id=${id}`).then((d: any) => d);
};
const getCurriculoVagaById = (query: string): Promise<any> => {
	console.log(`${GET_CURRICULOVAGABYID}?${query}`);
	return axios.get(`${GET_CURRICULOVAGABYID}?${query}`).then((d: any) => d);
};

const getVagasPostuladas = (query: string): Promise<any> => {
	console.log(`${GET_VAGASPOSTULADAS}?${query}`);
	return axios.get(`${GET_VAGASPOSTULADAS}?${query}`).then((d: any) => d);
};

const createVacancy = (vacancy: any): Promise<void> => {
	return axios.post(`${POST_VACANCY}`, vacancy).then((response: any) => response.data);
};

const registerForVacancy = (register: { id_vacancy: ID }): Promise<any> => {
	return axios.post(`${POST_VACANCY_REGISTER}`, register).then((response: any) => response.data);
};

const getMeetsByCouselor = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CONSEJERO}?${query}`).then((d: any) => d);
};

const getMeetsByCandidate = (query: string): Promise<any> => {
	return axios.get(`${GET_ALL_MEET_CANDIDATE}?${query}`).then((d: any) => d);
};

const getVerifyFreeDate = (query: string): Promise<any> => {
	return axios.get(`${AGENDA_VERIFY_DATE}?${query}`).then((d: any) => d);
};

const getRandomDate = (): Promise<any> => {
	return axios.get(`${AGENDA_RANDOM_DATE}`).then((d: any) => d);
};

const listCouselor = (): Promise<any> => {
	return axios.get(`${AGENDA_LIST_COUSELOR}`).then((d: any) => d);
};

const updateVacancyStatus = (vacancySingle: { id: ID; status: boolean }): Promise<any> => {
	return axios.put(UPDATE_VACANCY_STATUS, vacancySingle);
};

const storeMeetApi = (agenda: any): Promise<any> => {
	return axios.post(`${AGENDA_CREATE}`, agenda).then((response: any) => response.data);
};

const deleteMeetApi = (query: string): Promise<any> => {
	return axios.delete(`${AGENDA_DELETE}?${query}`).then((response: any) => response.data);
};

const getSearchVacantes = (query: string): Promise<any> => {
	return axios.get(`${GET_SEARCH_VACANTES}?${query}`).then((d: any) => d);
};

const getDocumentsZip = async (query: string): Promise<any> => {
	return axios.get(`${DOCUMENTS_ZIP}?${query}`, { responseType: 'blob' }).then((d: any) => d)
}

const getPositions = async (query?: any): Promise<any> => {
	console.log(`${GET_POSITIONS}?${query}`);
	const d = await axios.get(`${GET_POSITIONS}?${query}`);
	return d;
};

const getContractTypes = async (query?: any): Promise<any> => {
	console.log(`${GET_CONTRACT_TYPES}?${query}`);
	const d = await axios.get(`${GET_CONTRACT_TYPES}?${query}`);
	return d;
}

const getHiringStatus = async (query?: any): Promise<any> => {
	console.log(`${GET_HIRING_STATUS}?${query}`);
	const d = await axios.get(`${GET_HIRING_STATUS}?${query}`);
	return d;
}

export {
	getUsers,
	getUsersCurriculo,
	deleteUser,
	deleteSelectedUsers,
	getUserById,
	createUser,
	updateUser,
	getCompanies,
	getAllActiveCompanies,
	getCompaniesCounselor,
	createCompany,
	createCurriculo,
	createVacancy,
	getCurriculoById,
	getVagaCurriculoById,
	getVagaCurriculoId,
	getCurriculoVagaById,
	getCurriculoId,
	getCurriculos,
	getVacancyId,
	getVacancys,
	getUserCompelmentoId,
	registerForVacancy,
	getUserDataById,
	updateStatusUserToActive,
	updateStatusUserToDesactive,
	deleteUserById,
	deleteVacancyById,
	updateVacancy,
	updateUserPut,
	createUserPost,
	getCompanyId,
	updateCompany,
	updateUserComplementoPut,
	deleteEmpresaById,
	getMeetsByCouselor,
	getMeetsByCandidate,
	updateStatusEmpresa,
	emailCandidateEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
	updateVacancyStatus,
	getSearchVacantes,
	getVagaEmpresaCurriculoAll,
	getVagaPostulaCurriculoAll,
	getVagaCountCurriculoAll,
	getCurriculoAll,
	getUserCurriculoAll,
	getVagasPostuladas,
	getVerifyFreeDate,
	getRandomDate,
	storeMeetApi,
	deleteMeetApi,
	listCouselor,
	getDocumentsZip,
	getLocalidad,
	getAgendaVagaCurriculoAll,
	getAgendaCurriculoById,
	feedbackCandidate,
	getPositions,
	getContractTypes,
	getHiringStatus,
};
