import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link } from 'react-router-dom';
import {
	getVagaEmpresaCurriculoAll,
	getHiringStatus,
	feedbackCandidate,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import EmployerReqVacancyCardComponent from '../../components/EmployerReqVacancyCardComponent';
import {
	Alert,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Snackbar,
	TextField,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { initValue } from '../../util/Index';

const EmployerRequestedCandidatoVacancyPage = () => {
	const { t, i18n } = useTranslation();
	const { currentUser } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [hiringStatusList, setHiringStatusList] = useState<any[]>([
		{
			id: 1,
			pt_status: 'Candidatura Enviada',
			en_status: 'Application Sent',
			es_status: 'Solicitud Enviada',
		},
	]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});
	const [openModal, setOpenModal] = useState(false);
	const [messageModal, setMessageModal] = useState('');
	const [loading, setLoading] = useState(false);
	const [checkList, setCheckList] = useState('');
	const [companyData, setCompanyData] = useState<any>({
		id: 0,
		id_aprobado: false,
		id_desaprobado: false,
		id_abandonar: false,
	});
	const [isSelected, setIsSelected] = useState(false);
	const [selectedHiringStatus, setSelectedHiringStatus] = useState<any>({
		id: 0,
	});
	const [hiringFeedback, setHiringFeedback] = useState<any>('');
	const [modalData, setModalData] = useState<any>({
		id: 0,
		userId: 0,
		vacancyId: 0,
	});
	const [open, setOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [id, setId] = useState<string>('');
	const [idEmpresa, setIdEmpresa] = useState<string>('');
	const [idVacancy, setIdVacancy] = useState<string>('');
	const id_users = currentUser?.user?.id;
	const id_empresa = currentUser?.user?.empresa_id;
	const [menuState, setMenuState] = useState<any>({});

	const handleClickMenu = (event: any, id: any) => {
		setMenuState((prevState: any) => ({
			...prevState,
			[id]: {
				anchorEl: event.currentTarget,
				openMenu: true,
			},
		}));
	};

	const handleCloseMenu = (id: any) => {
		setMenuState((prevState: any) => ({
			...prevState,
			[id]: {
				anchorEl: null,
				openMenu: false,
			},
		}));
	};

	const currentLanguage = i18n.language.split('-')[0];

	const hiringStatusMap: { [key: string]: string } = {
		pt: 'pt_status',
		es: 'es_status',
		en: 'en_status',
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		let arr: any[] = [];
		for (let index = 0; index < 10; index++) {
			let x = {
				id: 1,
				vacancy: `El nombre de la vacante ${index}`,
				employer: `El nombre de lo empleador ${index}`,
				description: `CUIDADOR DE ADULTO MAYOR PREPARACIóN DE ALIMENTACIóN ESPECIAL ${index}`,
				status: 'Fechada',
			};
			arr.push(x);
		}
		//setData(arr);
		handleSubmit();
	}, []);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	const getHiringStatusList = async () => {
		try {
			const response = await getHiringStatus();
			setHiringStatusList(response.data?.hiringStatus);
		} catch (error) {
			console.error(error);
		}
	};

	const changeCandidateHiringStatus = async () => {
		setLoading(true);
		try {
			const retorno = await feedbackCandidate({
				id: modalData.id,
				id_vacancy: modalData.vacancyId,
				company_feedback: hiringFeedback,
				hiring_status: selectedHiringStatus.id,
			});

			setMessageModal(retorno.data.message);
			setOpenModal(true);
			setTimeout(() => {
				setOpen(true);
				handleCloseModal();
			}, 2000);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async () => {
		try {
			const mapNewFormatToOldFormat = (newFormat: any) => {
				return {
					totalPages: newFormat.totalPages || 1,
					total: newFormat.total,
					currentPage: newFormat.page.toString(),
					data: newFormat.data.map((item: any) => ({
						id: item.UserCV.id,
						id_user: item.UserCV.User.id,
						id_vagas: item.id_vacancy,
						id_empresa: item.CompanyVacancy.company_id,
						primer_nome: item.UserCV.User.UserComplement.first_name,
						numero_doc: item.UserCV.User.UserComplement.document_number,
						email: item.UserCV.User.email,
						celular: item.UserCV.User.UserComplement.phone_number,
						nombre_vacante: item.CompanyVacancy.job_title,
						razon_social: 'Empresa 11',
						//hiring_status: item.hiring_status,
						HiringStatus: item.HiringStatus,
					})),
				};
			};

			const users = await getVagaEmpresaCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}}&id_empresa=${id_empresa}&nome=${userData.nombre_candidato}`
			);

			await getHiringStatusList();

			const oldFormatUsers = mapNewFormatToOldFormat(users.data);

			setTotal(oldFormatUsers.total);
			setTotalPage(oldFormatUsers.totalPages);
			setData(oldFormatUsers.data);
		} catch (e: any) {
			console.error(e);
			var erro = e;
			alert(e + ' ' + e.response.data.error);
		}
	};

	const handleOpenModal = (data: any) => {
		setModalData({
			id: data.id,
			userId: data.userId,
			vacancyId: data.vacancyId,
		});
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setSelectedHiringStatus({ id: 0 });
		setHiringFeedback('');
		setModalData({
			userId: 0,
			vacancyId: 0,
		});
		setMessageModal('');
		setOpenModal(false);
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1 mb-6 mt-6'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<h3 className={styleScss.titleMobile}>{t('Gestión de candidatos')}</h3>
					<div className='col-sm-4 mt-4'>
						<div className='input-group mb-6'>
							<input
								type='text'
								className={`form-control ${styleScss.inputMobile}`}
								value={userData['nombre_candidato']}
								onChange={(e) => setUserData({ ...userData, nombre_candidato: e.target.value })}
								placeholder={t('Escriba el nombre del candidato')}
								aria-label='Escriba el nombre del candidato'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className='input-group-text'
									style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
									onClick={handleSubmit}
									id='searchCandidato'
								>
									<i
										className={`bi bi-search cursor-pointer text-primary ${styleScss.iconSearchMobile}`}
										style={{ fontSize: '20px' }}
									></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				{(data.lenght && !!hiringStatusList.length) !== 0 && (
					<div className={styleScss.HideTableMobile}>
						<table className={`table table-responsive table-striped`}>
							<thead className={`${styleScss['th-custom']}`}>
								<tr>
									<th scope='col'>
										<h4>{t('Candidato')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Documento')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Email')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Teléfono')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Vacante')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Status')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Acciones')}</h4>
									</th>
								</tr>
							</thead>
							<tbody className={`${styleScss['td-custom']}`}>
								{data.map((x: any, i: number) => (
									<tr key={i} id={i.toString()}>
										<th className='pt-3 pb-3' scope='row'>
											{x.primer_nome}
										</th>
										<td>{x.numero_doc}</td>
										<td>{x.email}</td>
										<td>{x.celular}</td>
										<td>{x.nombre_vacante}</td>
										<td>{x.HiringStatus[hiringStatusMap[currentLanguage]]}</td>
										<td>
											<Button
												id={`basic-button-${x.id_user}`}
												aria-controls={
													menuState[x.id_user]?.openMenu ? `basic-menu-${x.id_user}` : undefined
												}
												aria-haspopup='true'
												aria-expanded={menuState[x.id_user]?.openMenu ? 'true' : undefined}
												onClick={(e) => handleClickMenu(e, x.id_user)}
											>
												<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
											</Button>

											<Menu
												id={`basic-menu-${x.id_user}`}
												anchorEl={menuState[x.id_user]?.anchorEl}
												open={menuState[x.id_user]?.openMenu || false}
												onClose={() => handleCloseMenu(x.id_user)}
												MenuListProps={{
													'aria-labelledby': `basic-button-${x.id_user}`,
												}}
											>
												<MenuItem onClick={() => handleCloseMenu(x.id_user)}>
													<Link
														to={`/empleador/hoja-vida/${x.id_user}/${x.id_empresa}/${x.id_vagas}`}
													>
														{t('Ver candidato')}
													</Link>
												</MenuItem>
												<MenuItem onClick={() => handleCloseMenu(x.id_user)}>
													<p
														onClick={() =>
															handleOpenModal({
																id: x.id,
																userId: x.id_user,
																vacancyId: x.id_vagas,
															})
														}
														style={{ margin: 0 }}
													>
														{t('Feedback candidato')}
													</p>
												</MenuItem>
											</Menu>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{data.map((x: any, i: number) => (
					<EmployerReqVacancyCardComponent data={x} key={i} handleClickMenu={handleClickMenu} />
				))}
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{ border: 'none' }}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						{t('Comentarios sobre el candidato')}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						{t(`Por favor, seleccione el estado del candidato y deje sus comentarios.`)}
					</Typography>
					<FormLabel sx={{ fontSize: 14, fontWeight: 'bold', color: '#2B2B2B', marginTop: 2 }}>
						Status
					</FormLabel>
					<FormGroup aria-label='position' row>
						{!!hiringStatusList.length &&
							hiringStatusList.map((item: any, index: number) => (
								<FormControlLabel
									key={index}
									value={item.id}
									control={
										<Checkbox
											name={item.id}
											color='default'
											checked={selectedHiringStatus['id'] === item.id}
											value={selectedHiringStatus['id'] === item.id}
											onChange={(e) => {
												setSelectedHiringStatus(item);
											}}
										/>
									}
									label={item[hiringStatusMap[currentLanguage]]}
									labelPlacement='end'
								/>
							))}
					</FormGroup>
					<TextField
						className='mt-5 mb-5'
						fullWidth
						rows={10}
						id='observaciones'
						name='observaciones'
						label={t('Observaciones')}
						value={hiringFeedback}
						onChange={(e) => setHiringFeedback(e.target.value)}
						size='medium'
						multiline
						inputProps={{ MaxLength: 500 }}
						disabled={loading}
						helperText={hiringFeedback.length + '/500'}
						// helperText={`${funcionesLogros === undefined ? '0' : funcionesLogros?.length}/${2000}`}
					/>

					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-primary'
							onClick={() => changeCandidateHiringStatus()}
							disabled={
								selectedHiringStatus?.id && hiringFeedback.length > 0 && !loading ? false : true
							}
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							Salvar
						</button>
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							Cancelar
						</button>
					</div>
				</Box>
			</Modal>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={() => setOpen(false)}
			>
				<Alert
					onClose={() => setOpen(false)}
					variant={'filled'}
					severity={'success'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{t('Feedback enviado con éxito')}
				</Alert>
			</Snackbar>
		</>
	);
};

const EmployerRequestedCandidatoVacancyWrapper = () => {
	const { t } = useTranslation();

	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión de candidatos')}</PageTitle>
			<EmployerRequestedCandidatoVacancyPage />
		</>
	);
};

export { EmployerRequestedCandidatoVacancyWrapper };
