import { useContext, useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Grid,
	Box,
	Button,
	Divider,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { useAuth } from '../../../../../../app/modules/auth';
import { useParams } from 'react-router-dom';
import { formatMaskPhoneNumber12Digits, initValue } from '../../../../../util/Index';
import {
	getVacancyId,
	getCompaniesCounselor,
} from '../../../../apps/user-management/users-list/core/_requests';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from '../../../../../models/enums/RoleEnum';

export const Step1 = () => {
	const { t, i18n } = useTranslation();
	const { currentUser, auth } = useAuth();
	const { setStep, userData, setUserData, loadedData, setLoadedData } =
		useContext(multiStepContext);
	const { id } = useParams(); /*if it has ID: Edit Mode; else: Create Mode*/
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		if (id !== undefined && parseInt(id) > 0 && (!loadedData as boolean)) {
			console.log('tem id');
			getVacancy();
		}
		setUserData({ ...userData, id_empresa: currentUser?.user?.empresa_id });
		console.log(userData);
	}, []);

	useEffect(() => {
		if (auth?.user?.role !== RoleEnum.EMPLOYER) {
			getListCompanies();
		}
	}, [auth?.user?.role]);

	const getVacancy = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			try {
				const response = await getVacancyId(parseInt(id));

				const currentLanguage = i18n.language.split('-')[0];

				const positionNameMap: { [key: string]: string } = {
					pt: 'pt_name',
					es: 'es_name',
					en: 'en_name',
				};

				const positionName = response.data.Position[positionNameMap[currentLanguage]];

				// Mapeamento de/para
				const formattedResponse = {
					id: response.data.id,
					id_empresa: response.data.company_id,
					numero_puestos: response.data.position_count.toString(),
					nombre_vacante: response.data.job_title,
					responsable_vacante: response.data.job_responsible,
					persona_solicita_cargo: response.data.requester,
					telefono_contacto: response.data.contact_phone,
					Correo_eletrenico_contacto: response.data.contact_email,
					asesor_responsable: response.data.responsible_advisor,
					Describa_vacante: response.data.job_description,
					nivel_estudio: response.data.education_level,
					graduado: response.data.graduated ? true : false,
					formacion_titulo: response.data.education_title,
					requiere_experiencia_relacionada: response.data.experience_required ? true : false,
					requiere_capacitacion_especifica: response.data.training_required ? true : false,
					vacante_susceptible_teletrabajo: response.data.telework_possible ? true : false,
					rango_experiencia: response.data.experience_range,
					descricion_capacitacion_especifica: response.data.training_description,
					zip_code: response.data.zip_code,
					fecha_limite_aplicacion: response.data.application_deadline,
					fecha_estimada_incorporacion: response.data.estimated_start_date,
					tipo_contrato: response.data.contract_type,
					salario_mensual: parseFloat(response.data.monthly_salary).toFixed(2),
					jornada_laboral: response.data.work_schedule,
					vacante_confidencial: response.data.confidential_job ? '1' : '0',
					trabajo_suplementario: response.data.outsourced_job ? '1' : '0',
					tipo_vacante: response.data.job_type,
					punto_atencion: response.data.street,
					prestador_alterno: response.data.complement,
					districto: response.data.city,
					createdAt: response.data.created_at,
					status: response.data.status === 'true',
					position: positionName,
					street: response.data.street,
					city: response.data.city,
					state: response.data.state,
					country: response.data.country,
					complement: response.data.complement,
					district: response.data.district,
					latitude: response.data.latitude,
					longitude: response.data.longitude,
					Empresa: {
						id: response.data.company_id,
						idhashEmpresa: response.data.company_id,
						
					},
					UserCurriculos: [],
					NivelEducativo: {
						id: response.data.education_level,
						sigla_nivel_educativo: 'PG',
						desc_nivel_educativo: 'Universitaria',
					},
				};

				setUserData(formattedResponse);
				console.log('response', formattedResponse);
				setLoadedData(true);
			} catch (error: any) {
				console.error('error', error);
			}
		}
	};

	const getListCompanies = async () => {
		try {
			const retCompanies = await getCompaniesCounselor();
			console.log('Companies', retCompanies);
			setCompanies(retCompanies.data);
		} catch (error: any) {
			console.error('error', error);
		}
	};

	class validationForm {
		id_empresa?: string;
		nombre_vacante: string = initValue(userData['nombre_vacante']);
		// responsable_vacante: string = ''
		persona_solicita_cargo: string = initValue(userData['persona_solicita_cargo']);
		telefono_contacto: string = initValue(userData['telefono_contacto']);
		Correo_eletrenico_contacto: string = initValue(userData['Correo_eletrenico_contacto']);
		// asesor_responsable: string = ''
		constructor() {
			if (auth?.user?.role !== RoleEnum.EMPLOYER) {
				this.id_empresa = initValue(userData['id_empresa']);
			}
		}
	}

	const formSchema = useMemo(() => {
		if (auth?.user?.role !== RoleEnum.EMPLOYER) {
			return Yup.object().shape({
				id_empresa: Yup.string().required('Campo requerido'),
				nombre_vacante: Yup.string().required('Campo requerido'),
				persona_solicita_cargo: Yup.string()
					.required('Persona que solicita el cargo es requerido')
					.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
				telefono_contacto: Yup.string()
					.matches(/^\d+$/, 'Teléfono de contacto es inválido')
					.required('Teléfono es requerido'),
				Correo_eletrenico_contacto: Yup.string()
					.email('Correo electrónico inválido')
					.required('Correo electrónico es requerido'),
			});
		} else {
			return Yup.object().shape({
				nombre_vacante: Yup.string().required('Campo requerido'),
				persona_solicita_cargo: Yup.string()
					.required('Persona que solicita el cargo es requerido')
					.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
				telefono_contacto: Yup.string()
					.matches(/^\d+$/, 'Teléfono de contacto es inválido')
					.required('Teléfono es requerido'),
				Correo_eletrenico_contacto: Yup.string()
					.email('Correo electrónico inválido')
					.required('Correo electrónico es requerido'),
			});
		}
	}, []);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true);
			} catch (error) {
				setSubmitting(false);
				setStatus('Información incorrecta');
			}
		},
	});

	/* useEffect(() => {
		if(Object.values(userData).toString() === '') return
		for (const value of Object.keys(formik.values)) {
			formik.setFieldValue(`${value}`, userData[`${value}`])
		}
		let phoneFormat: string = userData['telefono_contacto'] || ''
		phoneFormat = phoneFormat.replaceAll(/\D/g, '')
		formik.setFieldValue('telefono_contacto', phoneFormat)

	}, []) */

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Datos del responsable')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				{auth?.user?.role !== RoleEnum.EMPLOYER && (
					<Grid xs={12} md={3} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel required id='id_empresa'>
								{t('Empresa')}
							</InputLabel>
							<Select
								labelId='id_empresa'
								{...formik.getFieldProps('id_empresa')}
								error={formik.touched.id_empresa && Boolean(formik.errors.id_empresa)}
								required
								name='id_empresa'
								id='id_empresa'
								label='Empresa'
								value={initValue(userData['id_empresa'])}
								onChange={(e) => {
									setUserData({ ...userData, id_empresa: e.target.value });
									formik.handleChange(e);
								}}
							>
								<MenuItem value=''>Seleccione...</MenuItem>
								{companies.map(({ id, razon_social }, i) => (
									<MenuItem value={id} key={i}>
										{razon_social}
									</MenuItem>
								))}
							</Select>
							<FormHelperText sx={{ color: '#d32f2f' }}>
								{formik.touched.id_empresa ? t(formik.errors.id_empresa || '') : ''}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				<Grid xs={12} md={auth?.user?.role !== 3 ? 4 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('nombre_vacante')}
						error={formik.touched.nombre_vacante && Boolean(formik.errors.nombre_vacante)}
						helperText={formik.touched.nombre_vacante ? t(formik.errors.nombre_vacante || '') : ''}
						required
						id='nombre_vacante'
						name='nombre_vacante'
						label={t('Nombre de la vacante')}
						value={initValue(userData['nombre_vacante'])}
						inputProps={{ maxLength: 100 }}
						onChange={(e) => {
							setUserData({ ...userData, nombre_vacante: e.target.value });
							formik.handleChange(e);
						}}
						size='medium'
					/>
				</Grid>
				{/* <Grid xs={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='responsable_vacante'>
								Responsable de la vacante
							</InputLabel>
							<Select
								{...formik.getFieldProps("responsable_vacante")}
								error={formik.touched.responsable_vacante && Boolean(formik.errors.responsable_vacante)}
								labelId='responsable_vacante'
								id='responsable_vacante'
								label='Responsable de la vacante'
							value={initValue(userData['responsable_vacante'])}
							onChange={(e) => setUserData({ ...userData, responsable_vacante: e.target.value })}
								>
								<MenuItem value=''>Seleccione...</MenuItem>
								{ddlResponsable.map(({ id, name }, i) => (
									<MenuItem value={id} key={i}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid> */}
				<Grid xs={12} md={auth?.user?.role !== 3 ? 4 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						{...formik.getFieldProps('persona_solicita_cargo')}
						error={
							formik.touched.persona_solicita_cargo && Boolean(formik.errors.persona_solicita_cargo)
						}
						helperText={
							formik.touched.persona_solicita_cargo
								? t(formik.errors.persona_solicita_cargo || '')
								: ''
						}
						label={t('Persona que cadastra el cargo')}
						placeholder={t('Persona que cadastra el cargo')}
						type='text'
						value={initValue(userData['persona_solicita_cargo'])}
						onChange={(e) => {
							setUserData({ ...userData, persona_solicita_cargo: e.target.value });
							formik.handleChange(e);
						}}
						inputProps={{ maxLength: 100 }}
						size='medium'
					/>
				</Grid>
				<Grid xs={12} md={auth?.user?.role !== 3 ? 4 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						inputProps={{ autoComplete: 'new-password', maxLength: 15 }}
						{...formik.getFieldProps('telefono_contacto')}
						error={formik.touched.telefono_contacto && Boolean(formik.errors.telefono_contacto)}
						helperText={
							formik.touched.telefono_contacto ? t(formik.errors.telefono_contacto || '') : ''
						}
						label={t('Teléfono de contacto')}
						placeholder={t('Ingresa un teléfono')}
						value={initValue(userData['telefono_contacto'])}
						onChange={(e) => {
							let val = e.target.value
								?.replaceAll(/[^\d]/g, '')
								?.replaceAll('-', '')
								?.replaceAll('+', '');
							let reg = /[0-9]|\./;
							if (reg.test(val) || val === '') {
								setUserData({ ...userData, telefono_contacto: formatMaskPhoneNumber12Digits(val) });
								formik.handleChange(e);
							}
						}}
						size='medium'
					/>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						{...formik.getFieldProps('Correo_eletrenico_contacto')}
						error={
							formik.touched.Correo_eletrenico_contacto &&
							Boolean(formik.errors.Correo_eletrenico_contacto)
						}
						helperText={
							formik.touched.Correo_eletrenico_contacto
								? t(formik.errors.Correo_eletrenico_contacto || '')
								: ''
						}
						label={t('Correo electrónico de contacto')}
						placeholder={t('Ingresa un correo electrónico')}
						value={initValue(userData['Correo_eletrenico_contacto'])}
						onChange={(e) => {
							setUserData({ ...userData, Correo_eletrenico_contacto: e.target.value });
							formik.handleChange(e);
						}}
						inputProps={{ type: 'email', maxLength: 100 }}
						size='medium'
					/>
				</Grid>
				{/* <Grid xs={4} mb={5} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='asesor_responsable'>
								Asesor responsable
							</InputLabel>
							<Select
								{...formik.getFieldProps("asesor_responsable")}
								error={formik.touched.asesor_responsable && Boolean(formik.errors.asesor_responsable)}
								labelId='asesor_responsable'
								id='asesor_responsable'
								label='Asesor responsable'
								value={initValue(userData['asesor_responsable'])}
							onChange={(e) => setUserData({ ...userData, asesor_responsable: e.target.value })}
								>
								<MenuItem value=''>Seleccione...</MenuItem>
								{ddlgestor.map(({ id, name }, i) => (
									<MenuItem value={id} key={i}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid> */}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button variant='contained' sx={{ mr: 1, width: 90, height: 40 }} disabled>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={(e) => {
						formik.handleSubmit();
						if (formik.isValid) setStep(2);
					}}
				>
					{t('Siguiente')}
				</Button>
			</Box>
		</>
	);
};
