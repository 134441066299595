import React, { useMemo, useState } from "react";
import { isValidFieldRequired } from "../../util/Index";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionEducationType = {
    id?: number;
    institute?: string;
    course?: string | undefined | null;
    course_type_id?: string | number | undefined | boolean;
    status?: string | number | undefined | null;
    end_year?: number | string | undefined | null;
    end_month?: number | string | undefined | null;
    end_date?: number | string | undefined | null;
}

export const useEducation = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { educations } = values;

    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);

    const [valueAdd, setValueAdd] = useState<SectionEducationType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);
    const [conventionalEducation, setConventionalEducation] = useState<boolean | undefined | null>(null);

    const [openRowEdit, setOpenRowEdit] = useState<number>(0); const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionEducationType[]>(educations || []);


    useMemo(() => {
        setIsSaving(false);
        if (educations?.length > 0) {
            setRows(educations)
            setConventionalEducation(true)
        }

    }, [educations])

    const setValues = (val: SectionEducationType) => {
        setValueAdd(val);
    }

    const handleConvetionalEducation = (bool: boolean | undefined | null) => {
        setConventionalEducation(bool)
        setFieldValue('traditionalEducation', bool);
    }

    const {
        institute,
        course,
        course_type_id,
        status,
        end_year,
        end_month,
        end_date
    } = valueAdd;


    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
        setIsSaving(false);
    };

    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('educations', rowsAdd);
    }

    const handleSaveEdit = () => {
        if (editMode) {
            setIsSaving(true);
            let item = rows.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit || parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    institute: institute as string,
                    course: course,
                    course_type_id: course_type_id,
                    status: status,
                    end_date: `${end_year}-${end_month}`,
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        setIsSaving(true);
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows.length + 1,
                    institute: institute as string,
                    course: course,
                    course_type_id: course_type_id,
                    status: status,
                    end_date: `${end_year}-${end_month}`,
                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Educación Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }

    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);
    };


    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];
        if (!isValidFieldRequired(institute))
            errors.push({ field: 'institutee', error: 'Campo requerido' });
        if (!isValidFieldRequired(course))
            errors.push({ field: 'Curso', error: 'Campo requerido' });
        if (!isValidFieldRequired(course_type_id))
            errors.push({ field: 'Curso', error: 'Campo requerido' });
        if (!isValidFieldRequired(status))
            errors.push({ field: 'Status', error: 'Campo requerido' });
        if (!isValidFieldRequired(course))
            errors.push({ field: 'Cunclusão', error: 'Campo requerido' });

        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }
        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        const doc = document.getElementById("instituteEducation");
        doc?.focus({
            preventScroll: false
        });

        setValueAdd({
            id: item.id,
            institute: item.institute as string,
            course: item.course,
            course_type_id: item.course_type_id,
            status: item.status,
            end_date: `${item.end_year}-${item.end_month}`,
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        conventionalEducation,
        handleConvetionalEducation,
        isSaving
    }

}