import React, { useEffect, useState } from "react";
import { isInValidLimitCharacter, isValidFieldRequired, msgInValidLimitCharacter } from "../../util/Index";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionCertificationType = {
    id?: number;
    name?: string;
    institute?: string | undefined | null;
    credential_id?: string | undefined | null;
    credential_url?: string | undefined | boolean;
    emite_date?: string | undefined | null;
    expiration_date?: string | undefined | null;
    dateEmissionMonth?: string | undefined | null;
    dateEmissionYear?: string | undefined | null | number;
    dateExpirationMonth?: string | undefined | null;
    dateExpirationYear?: string | undefined | null | number;
}

export const useCertification = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { t } = useTranslation();
    const { certifications } = values;
    const [isSaving, setIsSaving] = useState(false);

    const [valueAdd, setValueAdd] = useState<SectionCertificationType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);
    const [openRowEdit, setOpenRowEdit] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionCertificationType[]>(values.certifications || []);
    const setValues = (val: SectionCertificationType) => {
        setValueAdd(val);
    }

    const {
        name,
        institute,
        credential_id,
        credential_url,
        emite_date,
        expiration_date,
        dateEmissionMonth,
        dateEmissionYear,
        dateExpirationMonth,
        dateExpirationYear,
    } = valueAdd;

    useEffect(() => {
        setIsSaving(false);
        setRows(values.certifications)
    }, [certifications])

    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
        setIsSaving(false);

    };

    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('certifications', rowsAdd);
    }


    const handleSaveEdit = () => {
        setIsSaving(true);
        if (editMode) {
            let item = rows.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit ? openRowEdit : parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    name,
                    institute,
                    credential_id,
                    credential_url,
                    emite_date: `${dateEmissionMonth}/${dateEmissionYear}`,
                    expiration_date: `${dateExpirationMonth}/${dateExpirationYear}`,
                    dateEmissionMonth,
                    dateEmissionYear,
                    dateExpirationMonth,
                    dateExpirationYear,
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        setIsSaving(true);

        if (handleValidOnSubmit()) {
            const newRows = [
                ...rows,
                {
                    id: rows.length + 1,
                    name,
                    institute,
                    credential_id,
                    credential_url,
                    emite_date: `${dateEmissionMonth}/${dateEmissionYear}`,
                    expiration_date: `${dateExpirationMonth}/${dateExpirationYear}`,
                    dateEmissionMonth,
                    dateEmissionYear,
                    dateExpirationMonth,
                    dateExpirationYear,

                },
            ]
            setRows(newRows);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Certificación Agregada con éxito!`);
            saveToForm(newRows);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);
    };

    const checkValidNameLimitChar = (): boolean => {
        return isInValidLimitCharacter(name || '', 80, 0);
    }

    const msgInvalidName = (): string => {
        return msgInValidLimitCharacter(name || '', 80, 0, t)
    }

    const checkValidInstitutionLimitChar = (): boolean => {
        return isInValidLimitCharacter(institute || '', 80, 0);
    }

    const msgInvalidInstituition = (): string => {
        return msgInValidLimitCharacter(institute || '', 80, 0, t)
    }

    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];
        if (!isValidFieldRequired(name) || !checkValidNameLimitChar())
            errors.push({ field: 'name', error: msgInvalidName() });

        if (!isValidFieldRequired(name) || !checkValidInstitutionLimitChar())
            errors.push({ field: 'Nombre de la instituicione', error: msgInvalidInstituition() });

        if (!isValidFieldRequired(credential_id))
            errors.push({ field: 'id de la credencial', error: 'Campo requerido' });

        if (!isValidFieldRequired(dateEmissionMonth) || !isValidFieldRequired(dateEmissionYear))
            errors.push({ field: 'Fecha de ingreso', error: 'Campo requerido' });


        if (!isValidFieldRequired(dateExpirationMonth) || !isValidFieldRequired(dateExpirationYear))
            errors.push({ field: 'Fecha de retiro', error: 'Campo requerido' });



        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }

        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        // window.scrollTo(0, 170);
        const doc = document.getElementById("nameCert");
        doc?.focus({
            preventScroll: false
        });
        let yerEmit;
        let monthEmit;
        let yearExpire;
        let monthExpire;

        if (!item.dateEmissionMonth && !item.dateEmissionYear) {
            yerEmit = item.emite_date.split("/")?.[1];
            monthEmit = item.emite_date.split("/")?.[0];
        }

        if (!item.dateExpirationMonth && !item.dateExpirationMonth) {
            yearExpire = item.expiration_date.split("/")?.[1];
            monthExpire = item.expiration_date.split("/")?.[0];
        }

        setValueAdd({
            id: item.id,
            name: item.name,
            institute: item.institute,
            credential_id: item.credential_id,
            credential_url: item.credential_url,
            emite_date: `${item.emite_date}/${item.emite_date}`,
            expiration_date: `${item.expiration_date}/${item.expiration_date}`,
            dateEmissionMonth: monthEmit ? monthEmit : item.dateEmissionMonth,
            dateEmissionYear: yerEmit ? yerEmit : item.dateEmissionYear,
            dateExpirationMonth: monthExpire ? monthExpire : item.dateExpirationMonth,
            dateExpirationYear: yearExpire ? yearExpire : item.dateExpirationYear,
        })

    };

    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        isSaving
    }

}