import { useContext, useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { formatNumberToBRL, initValue, isValidFieldRequired } from '../../../../../util/Index';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	createVacancy,
	updateVacancy,
} from '../../../../apps/user-management/users-list/core/_requests';
import { t } from 'i18next';
import { useAuth } from '../../../core/Auth';
import { UserModel } from '../../../core/_models';
import { getContractTypes } from '../../../../apps/user-management/users-list/core/_requests';
import { useTranslation } from 'react-i18next';
import { TextFieldWrapper } from '../../../../../../_metronic/partials/content/texttinput/TextFieldWrapper';

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

const showDatePickeLimite = () => {
	// @ts-ignore
	document.querySelector('#fecha_limite_aplicacion').showPicker();
};
const showDatePickeEstimada = () => {
	// @ts-ignore
	document.querySelector('#fecha_estimada_incorporacion').showPicker();
};

const showDatePickerIngresso = () => {
	// @ts-ignore
	document.querySelector('#fecha_Ingresso').showPicker();
};
export const Step5 = () => {
	const { t, i18n } = useTranslation();
	const { setStep, userData, setUserData } = useContext(multiStepContext);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [fechaRetiro, setFechaRetiro] = useState<string>('');
	const [fechaIngresso, setFechaIngresso] = useState<string>('');
	const { currentUser, auth }: any = useAuth();
	const [contractTypes, setContractTypes] = useState<any[]>([]);

	useEffect(() => {
		getContractTypesAPI();
	}, []);

	const getContractTypesAPI = async () => {
		const response = await getContractTypes();

		const currentLanguage = i18n.language.split('-')[0];

		const languageMap: { [key: string]: string } = {
			pt: 'pt_type',
			es: 'es_type',
			en: 'en_type',
		};

		if (!languageMap[currentLanguage]) {
			console.error(`Linguagem não suportada: ${currentLanguage}`);
			return;
		}

		const formattedContractTypes = response.data.contractTypes.map((contractType: any) => ({
			id: contractType.id,
			type: contractType[languageMap[currentLanguage]],
		}));

		console.log(formattedContractTypes);

		setContractTypes(formattedContractTypes);
	};

	/* const formatNumberToBRL = (value: string) => {
		const number = parseFloat(value.replace(/[^0-9,-]+/g, '').replace(',', '.'));
		if (isNaN(number)) return '';
		return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	}; */

	/* const ddlTipoContrato = [
		{ id: 'AP', name: 'Aprendizaje' },
		{ id: 'OB', name: 'Obra' },
		{ id: 'OC', name: 'Otro' },
		{ id: 'PR', name: 'Prest. de Servicios' },
		{ id: 'TE', name: 'Temporal' },
		{ id: 'TF', name: 'Término Fijo' },
		{ id: 'TI', name: 'Término Indefinido' },
	]; */
	const ddlTipoSalario = [
		{ id: '1', name: 'Menos de 1 SMMLV' },
		{ id: '2', name: '1 SMMLV ' },
		{ id: '3', name: '1 a 2 SMMLV' },
		{ id: '4', name: '2 a 4 SMMLV' },
		{ id: '5', name: '4 a 6 SMMLV' },
		{ id: '6', name: '6 a 9 SMMLV' },
		{ id: '7', name: '9 a 12 SMMLV' },
		{ id: '8', name: '12 a 15 SMMLV' },
		{ id: '9', name: '15 a 19 SMMLV' },
		{ id: '10', name: '20 SMMLV en adelante' },
		{ id: '11', name: 'A convenir' },
	];
	const ddlJornada = [
		{ id: '1', name: 'Diurna' },
		{ id: '2', name: 'Nocturna' },
		{ id: '3', name: 'Mixta' },
	];
	const ddlTipoVacante = [{ id: '1', name: 'Local' }];
	const ddlJefeHogar = [
		{ id: '0', name: 'NO' },
		{ id: '1', name: 'SI' },
	];

	class validationForm {
		fecha_limite_aplicacion: string = initValue(userData['fecha_limite_aplicacion']);
		fecha_estimada_incorporacion: string = initValue(userData['fecha_estimada_incorporacion']);
		tipo_contrato: string = initValue(userData['tipo_contrato']);
		salario_mensual: string = initValue(userData['salario_mensual']);
		jornada_laboral: string = initValue(userData['jornada_laboral']);
		vacante_confidencial: string = initValue(userData['vacante_confidencial']);
		tipo_vacante: string = initValue(userData['tipo_vacante']);
		trabajo_suplementario: string = initValue(userData['trabajo_suplementario']);
		numero_puestos: string = initValue(userData['numero_puestos']);
		vacante_gestionada_prestador: string = initValue(userData['vacante_gestionada_prestador']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				fecha_limite_aplicacion: Yup.string().required('Campo requerido'),
				fecha_estimada_incorporacion: Yup.string().required('Campo requerido'),
				tipo_contrato: Yup.string().required('Campo requerido'),
				salario_mensual: Yup.string().required('Campo requerido'),
				jornada_laboral: Yup.string().required('Campo requerido'),
				vacante_confidencial: Yup.string().required('Campo requerido'),
				tipo_vacante: Yup.string().required('Campo requerido'),
				trabajo_suplementario: Yup.string().required('Campo requerido'),
				numero_puestos: Yup.string().required('Campo requerido'),
				// vacante_gestionada_prestador: Yup.string().required('Campo requerido'),
			}),
		[]
	);

	function convertToFloat(value: string) {
		let cleanValue = value.replace(/[R$\s.]/g, '');
		cleanValue = cleanValue.replace(',', '.');
		return parseFloat(cleanValue);
	  }

	const { id } = useParams();
	const handleSubmit = async () => {
		try {
			console.log('userData', userData);
			console.log(userData);

			let convertedUserData: any = {
				position: userData.position,
				position_count: parseInt(userData.numero_puestos),
				job_title: userData.nombre_vacante,
				job_responsible: '',
				requester: userData.persona_solicita_cargo,
				contact_phone: userData.telefono_contacto,
				contact_email: userData.Correo_eletrenico_contacto,
				responsible_advisor: '',
				job_description: userData.Describa_vacante,
				education_level: parseInt(userData.nivel_estudio),
				graduated: Boolean(parseInt(userData.graduado)),
				education_title: userData.formacion_titulo,
				experience_required: Boolean(parseInt(userData.requiere_experiencia_relacionada)),
				training_required: Boolean(parseInt(userData.requiere_capacitacion_especifica)),
				telework_possible: Boolean(parseInt(userData.vacante_susceptible_teletrabajo)),
				experience_range: userData.rango_experiencia,
				training_description: userData.descricion_capacitacion_especifica,
				application_deadline: new Date(userData.fecha_limite_aplicacion),
				estimated_start_date: new Date(userData.fecha_estimada_incorporacion),
				contract_type: userData.tipo_contrato,
				monthly_salary: convertToFloat(userData.salario_mensual),
				work_schedule: userData.jornada_laboral.toString(),
				confidential_job: Boolean(parseInt(userData.vacante_confidencial)),
				outsourced_job: Boolean(parseInt(userData.trabajo_suplementario)),
				job_type: userData.tipo_vacante,
				street: userData.street,
				complement: userData.complement,
				city: userData.city,
				state: userData.state,
				district: userData.district,
				country: userData.country,
				latitude: userData.latitude,
				longitude: userData.longitude,
				zip_code: userData.zip_code
			};

			if (id !== undefined && parseInt(id) > 0) {
				convertedUserData.id = parseInt(id);
				// setLoading(true);
				const retVacancy = await updateVacancy(convertedUserData);
				console.log(retVacancy);
				// setLoading(false);
				const alertMessage = t('¡Registro actualizado con éxito!');
				setOpen(true);
				setAlert(true);
				setAlertMsg(alertMessage);
				setTimeout(() => {
					setOpen(false);
					navigate(-1);
				}, 2000);
			} else {
				// setLoading(true);
				const retVacancy = await createVacancy(convertedUserData);
				console.log(retVacancy);
				// setLoading(false);
				setOpen(true);
				setAlert(true);
				const alertMessage = t('¡Registro actualizado con éxito!');
				setAlertMsg(alertMessage);
				setTimeout(() => {
					setOpen(false);
					navigate(-1);
				}, 2000);
				//navigate('/auth');
			}
		} catch (error) {
			console.error(error);
			// setLoading(false);
			setOpen(true);
			setAlert(false);
			setAlertMsg('¡Correu un erro!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};
	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values) => {
			try {
				handleSubmit();
			} catch (error) {
				console.error(error);
			}
		},
	});

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Requisitos')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					{/* <TextField
						fullWidth
						{...formik.getFieldProps('fecha_limite_aplicacion')}
						error={
							formik.touched.fecha_limite_aplicacion &&
							Boolean(formik.errors.fecha_limite_aplicacion)
						}
						helperText={
							formik.touched.fecha_limite_aplicacion ? formik.errors.fecha_limite_aplicacion : ''
						}
						required
						id='fecha_limite_aplicacion'
						name='fecha_limite_aplicacion'
						label='Fecha límite de aplicación'
						type='date'
						InputLabelProps={{ shrink: true }}
						onChange={(e) => {
							setUserData({ ...userData, fecha_limite_aplicacion: e.target.value })
							formik.handleChange(e)
						}}
						size='medium'
						value={userData['fecha_limite_aplicacion']}
					/> */}
					<TextField
						required
						fullWidth
						id='fecha_limite_aplicacion'
						label={t('Fecha límite de aplicación')}
						{...formik.getFieldProps('fecha_limite_aplicacion')}
						error={
							formik.touched.fecha_limite_aplicacion &&
							Boolean(formik.errors.fecha_limite_aplicacion)
						}
						helperText={
							formik.touched.fecha_limite_aplicacion
								? t(formik.errors.fecha_limite_aplicacion || '')
								: ''
						}
						onChange={(e) => {
							setUserData({ ...userData, fecha_limite_aplicacion: e.target.value });
							formik.handleChange(e);
						}}
						type='date'
						InputLabelProps={{ shrink: true }}
						size='medium'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<i
										className='bi bi bi-calendar2-plus-fill'
										style={{
											fontSize: '15px',
										}}
										onClick={() => showDatePickeLimite()}
									></i>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<TextField
						required
						fullWidth
						id='fecha_estimada_incorporacion'
						label={t('Fecha estimada de incorporación')}
						{...formik.getFieldProps('fecha_estimada_incorporacion')}
						error={
							formik.touched.fecha_estimada_incorporacion &&
							Boolean(formik.errors.fecha_estimada_incorporacion)
						}
						helperText={
							formik.touched.fecha_estimada_incorporacion
								? t(formik.errors.fecha_estimada_incorporacion || '')
								: ''
						}
						// {...formik.getFieldProps('fecha_estimada_incorporacion')}

						onChange={(e) => {
							setUserData({ ...userData, fecha_estimada_incorporacion: e.target.value });
							formik.handleChange(e);
						}}
						type='date'
						InputLabelProps={{ shrink: true }}
						size='medium'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<i
										className='bi bi bi-calendar2-plus-fill'
										style={{
											fontSize: '15px',
										}}
										onClick={() => showDatePickeEstimada()}
									></i>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='tipo_contrato'>
							{t('Tipo de contrato')}
						</InputLabel>
						<Select
							{...formik.getFieldProps('tipo_contrato')}
							error={formik.touched.tipo_contrato && Boolean(formik.errors.tipo_contrato)}
							labelId='tipo_contrato'
							id='tipo_contrato'
							required
							label={t('Tipo de contrato')}
							value={initValue(userData['tipo_contrato'])}
							onChange={(e) => {
								setUserData({ ...userData, tipo_contrato: e.target.value });
								formik.handleChange(e);
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{contractTypes?.map(({ id, type }, i) => (
								<MenuItem value={id} key={i}>
									{type}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.tipo_contrato ? t(formik.errors.tipo_contrato || '') : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						required
						id={'salario_mensual'}
						name={'salario_mensual'}
						label={t('Salario mensual')}
						value={formatNumberToBRL(formik.values.salario_mensual, false) || ''}
						onChange={(e: any) => {
							const formattedValue = formatNumberToBRL(e.target.value, false);
							setUserData({ ...userData, salario_mensual: formattedValue });
							formik.setFieldValue('salario_mensual', formattedValue);
						}}
						error={formik.touched.salario_mensual && Boolean(formik.errors.salario_mensual)}
						placeholder={t('Salario mensual')}
						size='medium'
						inputProps={{
							maxLength: 10,
						}}
						helperText={
							formik.touched.salario_mensual ? t(formik.errors.salario_mensual || '') : ''
						}
					/>
				</Grid>
				<Grid xs={6} md={3} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='jornada_laboral'>
							{t('Jornada laboral')}
						</InputLabel>
						<Select
							required
							{...formik.getFieldProps('jornada_laboral')}
							error={formik.touched.jornada_laboral && Boolean(formik.errors.jornada_laboral)}
							labelId='jornada_laboral'
							id='jornada_laboral'
							label={t('Jornada laboral')}
							value={initValue(userData['jornada_laboral'])}
							onChange={(e) => {
								setUserData({ ...userData, jornada_laboral: e.target.value });
								formik.handleChange(e);
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJornada.map(({ id, name }, i) => (
								<MenuItem value={id} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.jornada_laboral ? t(formik.errors.jornada_laboral || '') : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={6} md={3} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='vacante_confidencial'>
							{t('Esta vacante debe ser confidencial')}
						</InputLabel>
						<Select
							required
							{...formik.getFieldProps('vacante_confidencial')}
							error={
								formik.touched.vacante_confidencial && Boolean(formik.errors.vacante_confidencial)
							}
							labelId='vacante_confidencial'
							id='vacante_confidencial'
							label={t('Esta vacante debe ser confidencial')}
							value={initValue(userData['vacante_confidencial'])}
							onChange={(e) => {
								setUserData({ ...userData, vacante_confidencial: e.target.value });
								formik.handleChange(e);
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.vacante_confidencial
								? t(formik.errors.vacante_confidencial || '')
								: ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				{/* <Grid xs={5} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='vacante_gestionada_prestador'>
							Desea que la vacante sea gestionada por un prestador
						</InputLabel>
						<Select
							required
							{...formik.getFieldProps("vacante_gestionada_prestador")}
							error={formik.touched.vacante_gestionada_prestador && Boolean(formik.errors.vacante_gestionada_prestador)}
							labelId='vacante_gestionada_prestador'
							id='vacante_gestionada_prestador'
							label='Desea que la vacante sea gestionada por un prestador'
							value={initValue(userData['vacante_gestionada_prestador'])}
							onChange={(e) => {
								setUserData({ ...userData, vacante_gestionada_prestador: e.target.value })
								formik.handleChange(e)
							}}
						>
							<MenuItem value=''>Seleccione...</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id} key={i}>
									{name}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{formik.touched.vacante_gestionada_prestador ? formik.errors.vacante_gestionada_prestador : ''}</FormHelperText>
					</FormControl>
				</Grid> */}
				<Grid xs={6} md={3} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='trabajo_suplementario'>
							{t('Se requiere trabajo suplementario')}
						</InputLabel>
						<Select
							required
							{...formik.getFieldProps('trabajo_suplementario')}
							error={
								formik.touched.trabajo_suplementario && Boolean(formik.errors.trabajo_suplementario)
							}
							labelId='trabajo_suplementario'
							id='trabajo_suplementario<'
							label={t('Se requiere trabajo suplementario')}
							value={initValue(userData['trabajo_suplementario'])}
							onChange={(e) => {
								setUserData({ ...userData, trabajo_suplementario: e.target.value });
								formik.handleChange(e);
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.trabajo_suplementario
								? t(formik.errors.trabajo_suplementario || '')
								: ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={6} md={3} mb={5} pr={2} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='tipo_vacante'>
							{t('Tipo de vacante')}
						</InputLabel>
						<Select
							required
							{...formik.getFieldProps('tipo_vacante')}
							error={formik.touched.tipo_vacante && Boolean(formik.errors.tipo_vacante)}
							labelId='tipo_vacante'
							id='tipo_vacante'
							label={t('Tipo de vacante')}
							value={initValue(userData['tipo_vacante'])}
							onChange={(e) => {
								setUserData({ ...userData, tipo_vacante: e.target.value });
								formik.handleChange(e);
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlTipoVacante.map(({ id, name }, i) => (
								<MenuItem value={id} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.tipo_vacante ? t(formik.errors.tipo_vacante || '') : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid container direction='row' justifyContent='start'>
					{/* <Grid xs={6} md={3} mb={5} pr={2} item>
						<TextField
							fullWidth
							id='districto'
							name='districto'
							label='Distrito'
							value={initValue(userData['districto'])}
							 onChange={(e) => {
								formik.handleChange(e)
								setUserData({ ...userData, districto: e.target.value })
							}} 
							inputProps={{ maxLength: 100 }}
							size='medium'
						/>
					</Grid> */}
					<Grid xs={6} md={3} mb={5} item={true} sx={{ marginRight: 'auto' }}>
						<TextField
							fullWidth
							required
							{...formik.getFieldProps('numero_puestos')}
							error={formik.touched.numero_puestos && Boolean(formik.errors.numero_puestos)}
							id='numero_puestos'
							name='numero_puestos'
							label={t('Número de puestos')}
							value={initValue(userData['numero_puestos'])}
							onChange={(e) => {
								setUserData({ ...userData, numero_puestos: e.target.value });
								formik.handleChange(e);
							}}
							inputProps={{ type: 'number', maxLength: 100, min: 0 }}
							size='medium'
						/>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.numero_puestos ? t(formik.errors.numero_puestos || '') : ''}
						</FormHelperText>
					</Grid>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => {
						setStep(4);
					}}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ mr: 1, width: 90 }}
					onClick={() => {
						formik.handleSubmit();
						// if (formik.isValid) navigate('/auth');
					}}
				>
					{t('Finalizar')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={() => console.log('Implementar close')}
			>
				<Alert
					onClose={() => console.log('Implementar close')}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};
