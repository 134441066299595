import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Alert, AlertTitle, CircularProgress, Snackbar } from '@mui/material';
import { uploadCurriculum } from '../../../core/_requests';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const SectionImportFile = ({ onLoadFile, onClear }: any) => {
    const { t } = useTranslation();
    const [uploadFile, setUploadFile] = useState<any>(null);
    const [alert, setAlert] = useState(true);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const refInput: any = useRef(null);

    const onSelectFile = async (e: any) => {
        setLoading(true);
        try {
            const file: any = e.target.files[0];
            const fileType = file.type;

            // Verifica o tipo do arquivo e valida o tamanho para vídeos
            const maxSize = 7 * 1024 * 1024; // 7MB para vídeos
            if (fileType.startsWith('video/') && file.size > maxSize) {
                setAlert(false);
                setOpen(true);
                setLoading(false);
                return;
            }

            let data = new FormData();
            data.append('file', file);
            setUploadFile(file);

            const response = await uploadCurriculum(data);
            if (response) {
                onLoadFile(response);
                setAlert(true);
            } else {
                setAlert(false);
            }
            setOpen(true);
            setLoading(false);
        } catch (e) {
            setAlert(false);
            setOpen(true);
            setLoading(false);
        }
    };

    const handleOnClear = (): void => {
        setUploadFile(null);
        onClear();
    };

    const renderIcon = () => {
        if (!uploadFile) return <CloudUploadIcon fontSize="large" sx={{ fontSize: 100 }} />;
        return uploadFile.type.startsWith('application/pdf') ? (
            <PictureAsPdfIcon fontSize="large" sx={{ fontSize: 100 }} />
        ) : (
            <VideoLibraryIcon fontSize="large" sx={{ fontSize: 100 }} />
        );
    };

    return (
        <>
            <Button
                component="label"
                style={{ display: 'flex', marginBottom: 20, borderRadius: 20, flexDirection: 'column', backgroundColor: 'rgb(226 232 240)', padding: '32px 32px', alignItems: 'center' }}
            >
                {renderIcon()}
                <h4>Importar PDF ou Vídeo</h4>
                <VisuallyHiddenInput
                    ref={refInput}
                    accept=".pdf, video/*" // Aceita PDFs e vídeos
                    type="file"
                    onChange={onSelectFile}
                />
                {loading && <CircularProgress />}
            </Button>

            {uploadFile && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '15px 0' }}>
                    <div onClick={handleOnClear} style={{ fontSize: 16, color: '#3a3a3a' }}>
                        {t(uploadFile.type.startsWith('application/pdf') ? 'PDF_IMPORTED' : 'VIDEO_IMPORTED')}: {uploadFile?.name}
                    </div>
                    <a href="#" onClick={handleOnClear}>
                        <DeleteIcon fontSize="large" sx={{ fontSize: 25, color: '#3a3a3a' }} />
                    </a>
                </div>
            )}

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} onClose={() => setOpen(false)}>
                <Alert
                    onClose={() => setOpen(false)}
                    variant="filled"
                    severity={alert ? 'success' : 'error'}
                >
                    {alert ? <AlertTitle>{t('Success')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {alert ? t('MSG_UPLOAD_SUCCESS') : t('MSG_UPLOAD_ERROR')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SectionImportFile;