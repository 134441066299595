import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	CircularProgress,
	LinearProgress,
} from '@mui/material';
import {
	getCompanies,
	getDocumentsZip,
	deleteEmpresaById,
	updateStatusEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { useAuth } from '../../modules/auth';

import { initValue } from '../../util/Index';
import { useTranslation } from 'react-i18next';
import CompanyCardComponent from '../../components/CompanyCardComponent';
import MenuActionCompanyManagement from '../../components/MenuActionCompanyManagement';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CompanyStatusEnum } from '../../models/enums/CompanyStatusEnum';

interface StatusCompanyProps {
	id: number;
	status?: string;
	observaciones?: string;
}

const CompanyManagementPage = () => {
	const { t } = useTranslation();
	const { currentUser, auth } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const [companyData, setCompanyData] = useState<any>({
		id: 0,
	});
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const [idEmpresa, setIdEmpresa] = useState();
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});
	const navigate = useNavigate();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getCompaniesAPI();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const getCompaniesAPI = async () => {
		setLoading(true);
		try {
			const companies = await getCompanies(
				`page=${filter.page}&limit=${filter.limit}&company_name=${userData.nombre_employer}`
			);
			console.log(companies.data.data);
			setTotal(companies.data.total);
			setTotalPage(companies.data.totalPages);
			setData(companies.data.data);
			setLoading(false);
		} catch (e: any) {
			console.error(e);
			setLoading(false);
		}
	};
	const documentsZip = async (id: number) => {
		try {
			const docs = await getDocumentsZip(`company_id=${id}`);
			const blob = docs.data;
			const url = URL.createObjectURL(blob);
			window.location.href = url;
		} catch (error: any) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg(`${error.response.data.error}`);
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};

	const updateActivationCompany = async (id: number, type: boolean) => {
		try {
			if (type) {
				const retorno = await updateEmpresaToActive(`id=${id}`);
				setAlertMsg(retorno.data.message);
			} else {
				const retorno = await updateEmpresaToDesactive(`id=${id}`);
				setAlertMsg(retorno.data.message);
			}

			setOpen(true);
			setAlert(true);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
			getCompaniesAPI();
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};

	const changeStatusEmpresa = async () => {
		setLoading(true);
		try {
			const retorno = await updateStatusEmpresa(companyData);
			setAlertMsg(retorno.data.message);

			setOpen(true);
			setAlert(true);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
			setOpenAprobada(false);
			setOpenRechazada(false);
			setLoading(false);
			getCompaniesAPI();
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
			setOpenAprobada(false);
			setOpenRechazada(false);
			setLoading(false);
		}
	};

	const deleteEmpresa = async (id: any) => {
		try {
			await deleteEmpresaById(`id=${id}`);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`¡Empresa eliminada con éxito!`);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
			getCompaniesAPI();
			setOpenModal(false);
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
			setOpenModal(false);
		}
	};

	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = (id: any) => {
		setIdEmpresa(id);
		setOpenModal(true);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const [openAprobada, setOpenAprobada] = useState(false);
	const handleOpenAprobada = (id: any) => {
		setCompanyData({ id: id, status: CompanyStatusEnum.APPROVED });
		setOpenAprobada(true);
	};
	const handleCloseAprobada = () => {
		setOpenAprobada(false);
	};
	const [openRechazada, setOpenRechazada] = useState(false);
	const handleOpenRechazada = (id: any) => {
		setUserData({ ...userData, details: '' });
		setCompanyData({ id: id, status: CompanyStatusEnum.REJECTED });
		setOpenRechazada(true);
	};
	const handleCloseRechazada = () => {
		setOpenRechazada(false);
		setUserData({ ...userData, details: '' });
	};

	return (
		<>
			<div className='p-10 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<h3 className={styleScss.titleMobile}>{t('Gestión Empresas')}</h3>
					<div className='col-sm-4'>
						<div className='input-group'>
							<div
								className='col-sm-1'
								style={{
									display: 'contents',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<button
									onClick={() => navigate(-1)}
									className='btn btn-primary'
									style={{ background: '#C4D2E8', borderRadius: '4px', padding: '15px' }}
								>
									<i
										className='bi bi-arrow-left'
										style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
									></i>
								</button>
							</div>

							<input
								type='text'
								className='form-control'
								value={userData['nombre_employer']}
								onChange={(e) => setUserData({ ...userData, nombre_employer: e.target.value })}
								style={{ margin: '0 5px', borderRadius: '5px' }}
								placeholder={t('Busca la empresa')}
								aria-label="Recipient's username"
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className='input-group-text'
									style={{
										cursor: 'pointer',
										color: 'blue',
										textDecoration: 'underline',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100%',
									}}
									onClick={getCompaniesAPI}
									id='searchEmpresa'
								>
									<i className='bi bi-search' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				{loading ? (
					<LinearProgress style={{ marginTop: '10px' }} />
				) : (
					<>
						<div className={'table-limit table-responsive'} style={{ width: '100%' }}>
							<div className={styleScss.tableWeb}>
								<table id='table-list' className={`table table-striped ${styleScss['tt']}`}>
									<thead className={`${styleScss['th-custom']}`}>
										<tr>
											<th scope='col'>
												<h4>{t('Empresa')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Documento')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Responsable')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Correo Electrónico')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Vacantes registradas')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Estado')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Activo')}</h4>
											</th>
											<th scope='col'>
												<h4>{t('Acciones')}</h4>
											</th>
										</tr>
									</thead>
									<tbody className={`${styleScss['td-custom']}`}>
										{data.map((x: any, i: number) => (
											<tr key={i} id={i.toString()}>
												<td>{x?.company_name?.toLowerCase()}</td>
												<td>{x?.document_number}</td>
												<td>{x?.Users.length > 0 ? x.Users[0]?.UserComplement?.first_name : ''}</td>
												<td>{x?.Users.length > 0 ? x.Users[0]?.email : ''}</td>
												<td>{x?.CompanyVacancies?.length}</td>
												<td>{t(x.status)}</td>
												<td>{!!x?.active ? t('Sí') : t('No')}</td>
												<td>
													<MenuActionCompanyManagement
														data={{ id: x.id, active: x.active, status: x.status, CompanyFiles: x.CompanyFiles }}
														setAlert={setAlert}
														setAlertMsg={setAlertMsg}
														setOpen={setOpen}
														auth={auth}
														updateActivationCompany={updateActivationCompany}
														handleOpenRechazada={handleOpenRechazada}
														handleOpenAprobada={handleOpenAprobada}
														documentsZip={documentsZip}
														handleOpenModal={handleOpenModal}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							{data.map((x: any, i: any) => (
								<CompanyCardComponent
									key={i}
									data={x}
									setAlert={setAlert}
									setAlertMsg={setAlertMsg}
									setOpen={setOpen}
									auth={auth}
									updateActivationCompany={updateActivationCompany}
									handleOpenRechazada={handleOpenRechazada}
									handleOpenAprobada={handleOpenAprobada}
									documentsZip={documentsZip}
									handleOpenModal={handleOpenModal}
								/>
							))}
						</div>
					</>
				)}
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
			<div>
				<Dialog
					open={openModal}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{t('Exclusión de la empresa')}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{t('¿Está seguro de que desea eliminar esta empresa?')}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModal}
							autoFocus
						>
							{t('Cancelar')}
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => deleteEmpresa(idEmpresa)}
							autoFocus
						>
							{t('Borrar')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openAprobada}
					keepMounted
					onClose={handleCloseAprobada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{t('Confirmación de aprobación de empresa')}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{t('¿Está seguro de que desea aprobar esta empresa?')}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseAprobada}
							autoFocus
						>
							{t('Cancelar')}
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							autoFocus
						>
							{t('Aprobar')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openRechazada}
					keepMounted
					onClose={handleCloseRechazada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{t('Rechazar la empresa')}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{t('¿Está seguro de que desea rechazar esta empresa?')}
						</DialogContentText>
						<DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
							{t('Escriba las razones del rechazo a continuación')}
						</DialogContentText>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								sx={{ width: isSmallScreen ? '100%' : 500, height: 250, fontSize: '16px' }}
								rows={10}
								id='observaciones'
								name='observaciones'
								label={t('Observaciones')}
								value={initValue(companyData['details'])}
								onChange={(e) => setCompanyData({ ...companyData, details: e.target.value })}
								size='medium'
								multiline
								inputProps={{ maxLength: 300 }}
								disabled={loading}
								helperText={`${
									companyData['details'] === undefined
										? '0'
										: companyData['details']?.length
								}/${1200}`}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseRechazada}
							autoFocus
							disabled={!loading ? false : true}
						>
							{t('Cancelar')}
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							autoFocus
							disabled={
								companyData.details && companyData.details.length > 0 && !loading
									? false
									: true
							}
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{loading && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{t('Rechazar')}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};

const AdminCompanyManagementWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión Empresas')}</PageTitle>
			<CompanyManagementPage />
		</>
	);
};

export { AdminCompanyManagementWrapper };
