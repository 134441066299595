import { useEffect, useMemo, useRef, useState } from 'react';

import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Autocomplete,
	Popover,
	Menu,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useTranslation } from 'react-i18next';
import {
	formatStringToDate,
	getCheckedValueFormik,
	getErrorFormik,
	getValueFormik,
	isValidFieldRequired,
} from '../../../../../util/Index';
import { COUNTRY, HARDSKILS, SOFTSKILS, STATUS_COURSE } from '../../../../../constants/constants';
import { CurricullumSectionProps } from './CurricullumFormTypes';
import { useProfessionalProfile } from '../../../../../hooks/curriculum/useProfessionalProfile';
import { TextFieldWrapper } from '../../../../../../_metronic/partials/content/texttinput/TextFieldWrapper';
import { MaskInputWrapper } from '../../../../../../_metronic/partials/content/texttinput/MaskInputWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getPositions } from '../../../../apps/user-management/users-list/core/_requests';
import i18n from '../../../../../i18n';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export default function SectionProfessionalProfile({
	errors,
	handleChange,
	setFieldValue,
	values,
	listMaritalStatus,
}: CurricullumSectionProps) {
	const { t } = useTranslation();
	const currentSoftSkils = values?.softSkills;
	const keySoftSkills =
		t('idiom') == 'pt' ? SOFTSKILS['pt'] : t('idiom') == 'es' ? SOFTSKILS['es'] : SOFTSKILS['en'];
	const [listSoftskis, setListSoftskils] = useState(currentSoftSkils || []);
	const [optionsSoftskilss, setOptionsSoftSkills] = useState(keySoftSkills);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const keyHardSkills =
		t('idiom') == 'pt' ? HARDSKILS['pt'] : t('idiom') == 'es' ? HARDSKILS['es'] : HARDSKILS['en'];
	const currentHardskils = values?.hardSkills;
	const [listHardSkills, setListHardSkills] = useState(currentHardskils || []);
	const [optionsHardSkills, setOptionsHardSkills] = useState<any>(keyHardSkills);
	const [textPopover, setTextPopover] = useState('');

	const { valueAdd, setValues } = useProfessionalProfile({ values });
	console.log('keySoftSkills', keySoftSkills);
	const handleForm = (e: any) => {
		setValues({ ...valueAdd, [e.target.name]: e.target.value });
		setFieldValue(`${[e.target.name]}`, e.target.value);
	};

	useMemo(() => {
		if (!!currentSoftSkils) {
			setListSoftskils(currentSoftSkils);
			const val: any = [...optionsSoftskilss, ...currentSoftSkils];
			// setOptionsSoftSkills(val);
		}
		if (!!currentHardskils) {
			setListHardSkills(currentHardskils);
			const val: any = [...optionsHardSkills, ...currentHardskils];
			// setOptionsHardSkills(val);
		}
	}, [currentSoftSkils, currentHardskils]);

	const handleChangeSoftskils = (newValue: any) => {
		const val: any = [...newValue];
		setListSoftskils(val);
		setValues({ ...valueAdd, softSkills: val });
		setFieldValue('softSkills', val);
	};

	const handleChangeHardskils = (newValue: any) => {
		const val: any = [...newValue];
		setListHardSkills(val);
		setValues({ ...valueAdd, hardSkills: val });
		setFieldValue('hardSkills', val);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, text: string) => {
		setTextPopover(text);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const {
		first_name,
		last_name,
		birth_date,
		professional_profile,
		position,
		salary_expectation,
		home_office,
		willing_to_travel,
		marital_status,
		licensed,
		license_category,
		own_vehicle,
		military_certificate,
		phone_number,
		nationality,
		country,
		CurrentPosition,
		RequiredPosition,
	} = values;

	const { softSkills, hardSkills } = valueAdd;

	function formatNumberToBRL(event: React.ChangeEvent<HTMLInputElement>): void {
		let userInput: string = event.target.value.replace(/[^0-9]/g, '');
		console.log('formatted', userInput);

		if (userInput === '') {
			setValues({ ...valueAdd, salary_expectation: '0,00' });
		} else {
			let userInputAsNumber: number = parseInt(userInput, 10) / 100;
			let formattedNumber: string = `${userInputAsNumber
				.toFixed(2)
				.replace('.', ',')
				.replace(/(\d)(?=(\d{3})+\,)/g, '$1.')}`;
			console.log('formatted', formattedNumber);
			setValues({ ...valueAdd, salary_expectation: formattedNumber });
			setFieldValue('salary_expectation', formattedNumber);
		}
	}

	const [inputValue, setInputValue] = useState(values.position || '');
	const [inputValueTwo, setInputValueTwo] = useState(values.RequiredPosition || '');
	const [isLoading, setIsLoading] = useState(false);
	const [positions, setPositions] = useState([]);
	const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const debounceTimeoutRef2 = useRef<NodeJS.Timeout | null>(null); // Ref separada para o segundo input
	const inputRef = useRef<HTMLInputElement>(null);
	const inputRef2 = useRef<HTMLInputElement>(null);
	const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
	const [anchorEl3, setAnchorEl3] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const currentLanguage = i18n.language.split('-')[0];
		const positionMap: { [key: string]: string } = {
			pt: 'pt_name',
			es: 'es_name',
			en: 'en_name',
		};
	
		const RequiredPositionTranslated = RequiredPosition && RequiredPosition[positionMap[currentLanguage]];
		const CurrentPositionTranslated = CurrentPosition && CurrentPosition[positionMap[currentLanguage]];
		setInputValueTwo(RequiredPositionTranslated || '');
		setInputValue(CurrentPositionTranslated || '');
	}, []);

	const apiGetPositions = async (query?: string) => {
		if (!query || query?.length < 3) {
			setPositions([]);
			return;
		}
		setIsLoading(true);
		try {
			const positions2 = await getPositions(`name=${query}`);
			setAnchorEl2(inputRef.current);
			setPositions(positions2.data);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const apiGetPositionsTwo = async (query?: string) => { // Função separada para o segundo input
		if (!query || query?.length < 3) {
			setPositions([]);
			return;
		}
		setIsLoading(true);
		try {
			const positions2 = await getPositions(`name=${query}`);
			setAnchorEl3(inputRef2.current);
			setPositions(positions2.data);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputChange = (event: any) => {
		const { value } = event.target;
		setInputValue(value);
		setFieldValue('position', value);
	
		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}
	
		debounceTimeoutRef.current = setTimeout(() => {
			apiGetPositions(value);
		}, 750); // Debounce para o primeiro input
	};
	
	const handleInputChangeTwo = (event: any) => {
		const { value } = event.target;
		setInputValueTwo(value);
		setFieldValue('required_position', value);
	
		if (debounceTimeoutRef2.current) {
			clearTimeout(debounceTimeoutRef2.current);
		}
	
		debounceTimeoutRef2.current = setTimeout(() => {
			apiGetPositionsTwo(value);
		}, 750); // Debounce para o segundo input
	};

	const handleOptionClick = (option: string) => {
		setInputValue(option);
		setFieldValue('position', option);
		setAnchorEl2(null);
		setPositions([]);
	};
	
	const handleOptionClickTwo = (option: string) => {
		setInputValueTwo(option);
		setFieldValue('required_position', option);
		setAnchorEl3(null);
		setPositions([]);
	};
	
	const handleMenuClose = () => {
		setAnchorEl2(null);
		setAnchorEl3(null);
		setPositions([]);
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('DATA_HEAD')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						required
						id={'first_name'}
						name={'first_name'}
						label={t('Nombre')}
						value={first_name || ''}
						onChange={(e: any) => setValues({ ...valueAdd, first_name: e.target.value })}
						error={!isValidFieldRequired(first_name)}
						placeholder={t('Nombre')}
						size='medium'
						inputProps={{
							maxLength: 80,
						}}
						helperText={!isValidFieldRequired(first_name) ? t('Campo requerido') : ''}
					/>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						id={'last_name'}
						name={'last_name'}
						label={t('Apellido')}
						value={last_name || ''}
						error={!isValidFieldRequired(last_name)}
						onChange={handleForm}
						placeholder={t('Apellido')}
						size='medium'
						helperText={!isValidFieldRequired(last_name) ? t('Campo requerido') : ''}
						inputProps={{
							maxLength: 80,
						}}
					/>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<MaskInputWrapper
						fullWidth
						id={'birth_date'}
						mask={'99/99/9999'}
						name={'birth_date'}
						label={t('BIRTH_DATE')}
						value={birth_date}
						onChange={handleForm}
						placeholder={'DD/MM/YYYY'}
						InputLabelProps={{ shrink: true }}
						size='medium'
						inputProps={{
							maxLength: 80,
						}}
						error={!isValidFieldRequired(birth_date)}
						helperText={!isValidFieldRequired(birth_date) ? t('Campo requerido') : ''}
					/>
				</Grid>
			</Grid>
			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						required
						id={'salary_expectation'}
						name={'salary_expectation'}
						label={t('Aspiración salarial')}
						value={salary_expectation || ''}
						onChange={(e: any) => formatNumberToBRL(e)}
						error={!isValidFieldRequired(salary_expectation)}
						placeholder={t('Aspiración salarial')}
						size='medium'
						inputProps={{
							maxLength: 9,
						}}
						helperText={!isValidFieldRequired(salary_expectation) ? t('Campo requerido') : ''}
					/>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(home_office)}
					>
						<InputLabel id={'home_office'}>{t('HOME_OFFICE')}</InputLabel>
						<Select
							labelId={'home_office'}
							id={'home_office'}
							name={'home_office'}
							label={t('HOME_OFFICE')}
							value={home_office == true ? 1 : home_office == false ? 0 : ''}
							onChange={handleForm}
						>
							<MenuItem value={''}>{t('HOME_OFFICE')}</MenuItem>
							<MenuItem value={1}>{t('Sí')}</MenuItem>
							<MenuItem value={0}>{t('No')}</MenuItem>
						</Select>
						{!isValidFieldRequired(home_office) && (
							<FormHelperText>
								{!isValidFieldRequired(home_office) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(willing_to_travel)}
					>
						<InputLabel id={'willing_to_travel'}>{t('Posibilidad de viajar')}</InputLabel>
						<Select
							labelId={'willing_to_travel'}
							id={'willing_to_travel'}
							label={t('Posibilidad de viajar')}
							name={'willing_to_travel'}
							value={willing_to_travel == true ? 1 : willing_to_travel == false ? 0 : ''}
							onChange={handleForm}
						>
							<MenuItem value=''>{t('Posibilidad de viajar')}</MenuItem>
							<MenuItem value={1}>{t('Sí')}</MenuItem>
							<MenuItem value={0}>{t('No')}</MenuItem>
						</Select>
						{!isValidFieldRequired(willing_to_travel) && (
							<FormHelperText>
								{!isValidFieldRequired(willing_to_travel) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(marital_status)}
					>
						<InputLabel id={'marital_status'}>{t('Estado civil')}</InputLabel>
						<Select
							labelId={'marital_status'}
							id={'marital_status'}
							name={'marital_status'}
							label={t('Estado civil')}
							value={marital_status || ''}
							onChange={handleForm}
						>
							<MenuItem value=''>{t('Estado civil')}</MenuItem>
							{listMaritalStatus?.map((item: any) => (
								<MenuItem value={item.id} key={`marital-key-test-${item.id}`}>
									{item[`${t('FIELD_MARITAL_TYPE')}`]}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(marital_status) && (
							<FormHelperText>
								{!isValidFieldRequired(marital_status) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			</Grid>
			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(licensed)}
					>
						<InputLabel id={'licensed'}>{t('LICENSED_VEHICULE')}</InputLabel>
						<Select
							labelId={'licensed'}
							id={'licensed'}
							name={'licensed'}
							label={t('LICENSED_VEHICULE')}
							value={licensed == true ? 1 : licensed == false ? 0 : ''}
							onChange={handleForm}
						>
							<MenuItem value={''}>{t('LICENSED_VEHICULE')}</MenuItem>
							<MenuItem value={1}>{t('Sí')}</MenuItem>
							<MenuItem value={0}>{t('No')}</MenuItem>
						</Select>
						{!isValidFieldRequired(licensed) && (
							<FormHelperText>
								{!isValidFieldRequired(licensed) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						required={!!licensed}
						id={'license_category'}
						name={'license_category'}
						label={t('LICENCE_CATEGORY')}
						value={license_category || ''}
						onChange={handleForm}
						error={!!licensed && !isValidFieldRequired(license_category)}
						placeholder={t('LICENCE_CATEGORY')}
						size='medium'
						disabled={!licensed}
						inputProps={{
							maxLength: 15,
						}}
						helperText={
							!!licensed && !isValidFieldRequired(license_category) ? t('Campo requerido') : ''
						}
					/>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(own_vehicle)}
					>
						<InputLabel id={'own_vehicle'}>{t('OWN_VEHICULE')}</InputLabel>
						<Select
							labelId={'own_vehicle'}
							id={'own_vehicle'}
							label={t('OWN_VEHICULE')}
							name={'own_vehicle'}
							value={own_vehicle == true ? 1 : own_vehicle == false ? 0 : ''}
							onChange={handleForm}
						>
							<MenuItem value=''>{t('OWN_VEHICULE')}</MenuItem>
							<MenuItem value={1}>{t('Sí')}</MenuItem>
							<MenuItem value={0}>{t('No')}</MenuItem>
						</Select>
						{!isValidFieldRequired(own_vehicle) && (
							<FormHelperText>
								{!isValidFieldRequired(own_vehicle) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(military_certificate)}
					>
						<InputLabel id={'military_certificate'}>{t('MILITARY_CERTIFICATE')}</InputLabel>
						<Select
							labelId={'military_certificate'}
							id={'military_certificate'}
							label={t('MILITARY_CERTIFICATE')}
							name='military_certificate'
							value={military_certificate == true ? 1 : military_certificate == false ? 0 : ''}
							onChange={handleForm}
						>
							<MenuItem value=''>{t('MILITARY_CERTIFICATE')}</MenuItem>
							<MenuItem value={1}>{t('Sí')}</MenuItem>
							<MenuItem value={0}>{t('No')}</MenuItem>
						</Select>
						{!isValidFieldRequired(military_certificate) && (
							<FormHelperText>
								{!isValidFieldRequired(military_certificate) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			</Grid>
			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						required
						id={'phone_number'}
						name={'phone_number'}
						label={t('Teléfono de contacto')}
						value={phone_number || ''}
						onChange={handleForm}
						error={!isValidFieldRequired(phone_number)}
						placeholder={t('Teléfono de contacto')}
						size='medium'
						inputProps={{
							maxLength: 80,
						}}
						helperText={!isValidFieldRequired(phone_number) ? t('Campo requerido') : ''}
					/>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<TextFieldWrapper
						fullWidth
						id={'nationality'}
						name={'nationality'}
						label={t('Nacionalidad')}
						value={nationality || ''}
						error={!isValidFieldRequired(nationality)}
						onChange={handleForm}
						placeholder={t('Nacionalidad')}
						size='medium'
						helperText={!isValidFieldRequired(nationality) ? t('Campo requerido') : ''}
						inputProps={{
							maxLength: 80,
						}}
					/>
				</Grid>
				<Grid xs={12} md={4} mb={5} pr={2} item>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(country)}
					>
						<InputLabel id={'country'}>{t('País')}</InputLabel>
						<Select
							labelId={'country'}
							id={'country'}
							label={t('País')}
							value={country}
							name='country'
							onChange={handleForm}
						>
							<MenuItem value=''>{t('País')}</MenuItem>
							{COUNTRY.map((item) => (
								<MenuItem value={item.id} key={`${item.id}`}>
									{item.name}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(country) && (
							<FormHelperText>
								{!isValidFieldRequired(country) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			</Grid>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					Perfil profissional
				</Typography>
			</Box>

			<Grid container direction='row' justifyContent='space-between' width={'100%'}>
				<Grid xs={12} mb={5} pr={2} item>
					<Grid xs={12} mb={5} pr={2} item>
						<TextField
							ref={inputRef}
							value={inputValue}
							onChange={handleInputChange}
							label='Cargo atual'
							required
							fullWidth
							placeholder='Digite para iniciar a pesquisa'
							InputProps={{
								endAdornment: isLoading ? (
									<InputAdornment position='end'>
										<CircularProgress size={20} />
									</InputAdornment>
								) : null,
							}}
						/>
						<Menu
							anchorEl={anchorEl2}
							open={Boolean(anchorEl2) && positions.length > 0}
							onClose={handleMenuClose}
							PaperProps={{
								style: {
									maxHeight: 200,
									width: inputRef.current ? inputRef.current.clientWidth : 'auto',
								},
							}}
							disableAutoFocus
						>
							{positions.map((option, index) => (
								<MenuItem key={index} onClick={() => handleOptionClick(option)}>
									{option}
								</MenuItem>
							))}
						</Menu>
					</Grid>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl2}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<Typography sx={{ p: 2 }}>{textPopover}</Typography>
					</Popover>
				</Grid>

				<Grid xs={12} mb={5} pr={2} mt={-5} item>
					<Grid xs={12} mb={5} pr={2} item>
						<TextField
							ref={inputRef2}
							value={inputValueTwo}
							onChange={handleInputChangeTwo}
							label='Pesquisar cargo desejado'
							required
							fullWidth
							placeholder='Digite para iniciar a pesquisa'
							InputProps={{
								endAdornment: isLoading ? (
									<InputAdornment position='end'>
										<CircularProgress size={20} />
									</InputAdornment>
								) : null,
							}}
						/>
						<Menu
							anchorEl={anchorEl3}
							open={Boolean(anchorEl3) && positions.length > 0}
							onClose={handleMenuClose}
							PaperProps={{
								style: {
									maxHeight: 200,
									width: inputRef2.current ? inputRef2.current.clientWidth : 'auto',
								},
							}}
							disableAutoFocus
						>
							{positions.map((option, index) => (
								<MenuItem key={index} onClick={() => handleOptionClickTwo(option)}>
									{option}
								</MenuItem>
							))}
						</Menu>
					</Grid>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl3}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<Typography sx={{ p: 2 }}>{textPopover}</Typography>
					</Popover>
				</Grid>

				<Grid xs={12} md={12} mb={5} pr={2} item>
					<TextFieldWrapper
						required
						fullWidth
						multiline
						rows={4}
						id='professional_profile'
						name='professional_profile'
						label={t('PROFESSIONAL_PROFILER')}
						value={professional_profile}
						// onBlur={handleChange}
						placeholder={t('PROFESSIONAL_TOOLTIP')}
						onChange={handleForm}
						size='medium'
						error={getErrorFormik(errors, 'professional_profile')}
						helperText={getErrorFormik(errors, 'professional_profile') ? t('Campo requerido') : ''}
						inputProps={{
							maxLength: 1000,
							minLength: 250,
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position='end'
									onClick={(e: any) => handleClick(e, t('PROFESSIONAL_TOOLTIP'))}
									style={{ marginLeft: 5 }}
								>
									<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} mb={5}>
				<Grid xs={12} md={12} pr={1} sx={{ display: 'block' }} item={true}>
					<Autocomplete
						multiple
						fullWidth
						isOptionEqualToValue={(option, value) => value === option}
						id='checkboxes-tags-demo'
						options={optionsSoftskilss}
						value={listSoftskis}
						disableCloseOnSelect
						onChange={(event, newValue) => {
							handleChangeSoftskils(newValue);
						}}
						getOptionLabel={(option: any) => option}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option}
							</li>
						)}
						style={{ paddingRight: 12, width: '100%' }}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								label={t('SOFTSKILLS')}
								placeholder='Selecione uma ou mais habilidades profissionais que voce tenha.'
								// InputProps={{
								//     endAdornment: (
								//         <InputAdornment position='end' onClick={(e: any) => handleClick(e, t('SOFTSKILLS_TOOLTIP'))} style={{ marginLeft: 5 }}>
								//             <i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
								//         </InputAdornment>
								//     ),
								// }}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} mb={5}>
				<Grid xs={12} md={12} pr={1} sx={{ display: 'block' }} item={true}>
					<Autocomplete
						multiple
						fullWidth
						id='checkboxes-tags-hardskilss'
						options={optionsHardSkills}
						value={listHardSkills}
						disableCloseOnSelect
						isOptionEqualToValue={(option: any, value: any) => value === option}
						onChange={(event, newValue) => {
							handleChangeHardskils(newValue);
						}}
						getOptionLabel={(option: any) => option}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option}
							</li>
						)}
						style={{ paddingRight: 12, width: '100%' }}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('HARDSKILLS')}
								placeholder='Selecione uma ou mais habilidades profissionais que voce tenha.'
								// InputProps={{
								//     endAdornment: (
								//         <InputAdornment position='end' onClick={(e: any) => handleClick(e, t('HARDSKILLS_TOOLTIP'))} style={{ marginLeft: 5 }}>
								//             <i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
								//         </InputAdornment>
								//     ),
								// }}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</>
	);
}
