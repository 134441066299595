import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Snackbar, AlertTitle, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { Rating } from '@mui/material';
import { PageTitle } from '../../../_metronic/layout/core';
import {
	getCurriculoById,
	emailCandidateEmpresa,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { initValue, setValueOrDefault } from '../../util/Index';
import { t } from 'i18next';

const ResumePage = () => {
	const parms = useParams();
	const { currentUser } = useAuth();
	const navigate = useNavigate();

	const [resume, setResume] = useState<any>({});
	interface StatusCompanyProps {
		id: number;
		id_Empresa?: number;
		id_Vacancy?: number;
		observaciones?: string;
	}
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [companyData, setCompanyData] = useState<any>({
		id: 0,
	});
	const [openExperience, setOpenExperience] = useState(true);
	const [openEducation, setOpenEducation] = useState(true);
	const [openOccupationalInterest, setOpenOccupationalInterest] = useState(true);
	const [openKnowledge, setOpenKnowledge] = useState(true);
	const [openLanguages, setOpenLanguages] = useState(true);

	//MODAL
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const [messageModal, setMessageModal] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchData().catch(console.error);
	}, []);

	const fetchData = async () => {
		console.log(parms);
		if (parms.id !== undefined) {
			const mapCurriculoById = (data: any) => {
				return {
					curriculoListId: {
						estado_civil: data.UserCV.marital_status,
						tipo_doc_adicional: null,
						numero_doc_adicional: null,
						sexo: null,
						pais_nascimento: null,
						nacionalidad: null,
						perfil_laboral: data.UserCV.professional_profile,
						departamento_nacimiento: null,
						municipio_nacimiento: null,
						libreta_militar: data.UserCV.military_certificate,
						tipo_libreta: null,
						numero_libreta: null,
						reconoce_focalizada: 'No',
						jefe_hogar: null,
						id_Afrocolombianos: null,
						id_Indigenas: null,
						id_Negros: null,
						id_Palenqueros: null,
						id_Raizales: null,
						id_Rrom: null,
						id_Fisica: null,
						id_Cognitiva: null,
						id_Sordoceguera: null,
						id_Multiple: null,
						id_Psicosocial: null,
						id_Auditiva: null,
						id_Estuvo: null,
						id_Personas: null,
						id_Retornado: null,
						id_Vend_informales: null,
						id_Problema: null,
						id_Epilepsia: null,
						id_Consumo: null,
						id_Transtorno: null,
						id_Victima: false,
						id_jovem_mayor: null,
						id_persona_habitabilidad: null,
						id_persona_joven: null,
						id_persona_mayor_50: null,
						id_persona_migrante: null,
						id_persona_act_sexuales: null,
						id_persona_trans: null,
						id_persona_reincorporación: null,
						id_discapacidad_certificada: null,
						id_mujer: null,
						id_persona_victima_trata: null,
						id_personas_probreza: null,
						id_problacion_libertad_3_meses: null,
						id_problacion_rural: null,
						id_reciclador: null,
						id_vendedor_informal: null,
						id_victima_violencia_genero: null,
						id_victima_conflicto_armado: null,
						id_visual: null,
						otro_telefono: null,
						observaciones: null,
						pais_residencia: null,
						pais_retornado: null,
						departamento_residencia: null,
						municipio_residencia: null,
						bairro_residencia: null,
						pertence: null,
						prestador_preferencia: null,
						punta_atencion: null,
						local: null,
						id_coordenada: null,
						id_coordenada_02: null,
						id_coordenada_03: null,
						id_coordenada_04: null,
						id_coordenada_05: null,
						id_coordenada_06: null,
						id_coordenada_07: null,
						id_coordenada_08: null,
						id_coordenada_09: null,
						tipo_complemento: null,
						complemento: null,
						codigo_postal: null,
						direccion_residencia: null,
						situacion_laboral_actual: null,
						aspiracion_salarial: data.UserCV.salary_expectation,
						Interes_ofertas_teletrabajo: data.UserCV.home_office ? 'Yes' : 'No',
						posibilidad_trasladarse: data.UserCV.willing_to_travel ? 'Yes' : 'No',
						possibilidade_viajar: null,
						conduces_carro: data.UserCV.licensed ? 'Yes' : 'No',
						categoria_licencia_carro: data.UserCV.license_category,
						conduces_moto: null,
						categoria_licencia_moto: null,
						Prop_medio_transporte: data.UserCV.own_vehicle ? 'Yes' : 'No',
						desc_url: null,
						latitud: null,
						longitud: null,
						localidad: null,
						finalizado: data.UserCV.completed,
						cargo_requerido: data.UserCV.required_position,
						CurriculoIdiomas: [],
						CurriculoCargos: [],
						Cargos: [],
						CurriculoCapacitaciones: data.UserCV.CvCourses.map((course: any) => ({
							id: course.id,
							id_user_curriculo: course.id_user_cv,
							name: course.name,
							created_at: course.created_at,
							updated_at: course.updated_at,
						})),
						CurriculoConocimientos: data.UserCV.CvHardSkills.map((skill: any) => ({
							id: skill.id,
							id_user_curriculo: skill.id_user_cv,
							skill: skill.skill,
							created_at: skill.created_at,
							updated_at: skill.updated_at,
						})),
						CurriculoExperienciaLaborals: data.UserCV.CvProfessionalHistories.map((history: any) => ({
							id: history.id,
							id_user_curriculo: history.id_user_cv,
							funcionesLogros: history.activity,
							//tpExperienciaLaboral: 'INDEPENDIENTE',
							productoServicio: null,
							cuaPersonasTrabajan: null,
							//sector: 'INDUSTRIAS MANUFACTURERAS',
							cargo: history.position,
							//cargoEquivalente: 'ANALISTA DE OPERACIONES',
							nombreEmpresa: history.company,
							telefonoEmpresa: '',
							//paisEmpresa: 'COLOMBIA',
							fechaIngresso: history.start_date,
							trabajandoMismoTrabajo: null,
							fechaRetiro: history.end_date,
						})),
						CurriculoNivelEducativos: data.UserCV.CvCertifications.map((cert: any) => ({
							id: cert.id,
							id_user_curriculo: cert.id_user_cv,
							interessadoPractica: true,
							nivelEducativo: 'Técnica Laboral',
							tituloObtenido: cert.name,
							tituloHomologado: cert.name,
							areaDesempeno: '',
							nucleoConocimiento: '',
							Institucion: cert.institute,
							paisDelCurso: 'COLOMBIA',
							estadoDelCurso: 'BOGOTÁ, D.C.',
							fechafinalizacion: cert.expiration_date,
							idObservaciones: '',
							txtNumeroTarjProf: cert.credential_id,
							fechaTarjProf: cert.emite_date,
						})),
						complementoDirecao: [],
					},
					usercomplemento: {
						id: data.UserComplement.id,
						id_users: data.UserComplement.user_id,
						tipo_doc: data.UserComplement.document_type,
						numero_doc: data.UserComplement.user_id,
						primer_nome: data.UserComplement.first_name,
						segundo_nome: null,
						primer_apelido: data.UserComplement.last_name,
						segundo_apelido: null,
						fecha_nascimiento: null,
						ciudad: null,
						estrato: null,
						localidad: null,
						upz: null,
						bairro: null,
						celular: null,
						correo: data.email,
						cargo: null,
						pregunta_seguridad: null,
						respuesta: null,
						genero: null,
						User: {
							id: data.UserCV.user_id,
							empresa_id: null,
							email: data.email,
							passwordHash: null,
							idHash: null,
							role: null,
							active: data.active,
							status: data.status,
							createdAt: data.UserCV.created_at,
							updatedAt: data.UserCV.updated_at,
						},
					},
				};
			};

			const {data} = await getCurriculoById(`userId=${parms.id}`);
			console.log('data', data.dataCV);
			const oldFormatResume = mapCurriculoById(data.dataCV);

			console.log('oldFormatResume', oldFormatResume);

			setResume(oldFormatResume);

			/* if (parms.id_empresa !== undefined) {
				setCompanyData({
					...companyData,
					id: Number(parms.id),
					id_Empresa: Number(parms.id_empresa),
					id_Vacancy: Number(parms.id_vacancy),
				});
			} */
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const changeEnviaEmail = async () => {
		setLoading(true);
		try {
			const retorno = await emailCandidateEmpresa({
				id: parms.id,
				id_vacancy: parms.id_vacancy,
				details: companyData.observaciones,
			});
			// TODO - Mensagem completa retorna do back, não tem como traduzir.
			setAlertMsg(retorno.data.message);

			setOpen(true);
			setAlert(true);
			setTimeout(() => {
				setOpen(false);
			}, 4000);
			setLoading(false);
		} catch (error: any) {
			if (error?.response?.data) {
				setAlertMsg(error.response.data);
			} else {
				setAlertMsg('Ha ocurrido un error!');
			}

			setOpen(true);
			setAlert(false);
			setTimeout(() => {
				setOpen(true);
			}, 2000);
			setLoading(false);
			handleClose();
		}
	};
	const ddlIdioma = [
		{ id: '1', name: 'Alemán' },
		{ id: '2', name: 'Arabe' },
		{ id: '3', name: 'Búlgaro' },
		{ id: '4', name: 'Catalán' },
		{ id: '5', name: 'Checo' },
		{ id: '6', name: 'Chino' },
		{ id: '7', name: 'Coreano' },
		{ id: '8', name: 'Eslovaco' },
		{ id: '9', name: 'Esloveno' },
		{ id: '10', name: 'Español' },
		{ id: '11', name: 'Estonio' },
		{ id: '12', name: 'Finlandés' },
		{ id: '13', name: 'Francês' },
		{ id: '14', name: 'Griego' },
		{ id: '15', name: 'Hebreo' },
		{ id: '16', name: 'Holandés' },
		{ id: '17', name: 'Húngaro' },
		{ id: '18', name: 'Indonesio' },
		{ id: '19', name: 'Inglés' },
		{ id: '20', name: 'Islandés' },
		{ id: '21', name: 'Italiano' },
		{ id: '22', name: 'Japonés' },
		{ id: '23', name: 'Letonia' },
		{ id: '24', name: 'Lituano' },
		{ id: '25', name: 'Neerlandés' },
		{ id: '26', name: 'Noruego' },
		{ id: '27', name: 'Polaco' },
		{ id: '28', name: 'Portugués' },
		{ id: '29', name: 'Rumano' },
		{ id: '30', name: 'Ruso' },
		{ id: '31', name: 'Serbio' },
		{ id: '32', name: 'Sueco' },
		{ id: '33', name: 'Turco' },
		{ id: '34', name: 'Lengua de señas para sordos' },
		{ id: '35', name: 'Achagua' },
		{ id: '36', name: 'Arhuaco' },
		{ id: '37', name: 'Awa' },
		{ id: '38', name: 'Baniva' },
		{ id: '39', name: 'Barbacoas' },
		{ id: '40', name: 'Barí' },
		{ id: '41', name: 'Bora' },
		{ id: '42', name: 'Cabiyarí' },
		{ id: '43', name: 'Chimila' },
		{ id: '44', name: 'Coconuco' },
		{ id: '45', name: 'Cuaiquer' },
		{ id: '46', name: 'Curripaco' },
		{ id: '47', name: 'Dorasque' },
		{ id: '48', name: 'Guaibo' },
		{ id: '49', name: 'Guatuso' },
		{ id: '50', name: 'Guaymí' },
		{ id: '51', name: 'Jucuna' },
		{ id: '52', name: 'Kamsá' },
		{ id: '53', name: 'Macu' },
		{ id: '54', name: 'Matagalpa' },
		{ id: '55', name: 'Misquito' },
		{ id: '56', name: 'Motilon' },
		{ id: '57', name: 'Paéz' },
		{ id: '58', name: 'Paya' },
		{ id: '59', name: 'Piapoco' },
		{ id: '60', name: 'Puinave' },
		{ id: '61', name: 'Quechua' },
		{ id: '62', name: 'Saliba' },
		{ id: '63', name: 'Sibundoy' },
		{ id: '64', name: 'Talamanca' },
		{ id: '65', name: 'Ticuna' },
		{ id: '66', name: 'Tunebo' },
		{ id: '67', name: 'Wayúu' },
		{ id: '68', name: 'Witoto' },
		{ id: '69', name: 'Yucuna' },
		{ id: '70', name: 'Creole' },
		{ id: '73', name: 'Otro' },
	];

	return (
		<div style={{ marginTop: 36 }}>
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mt-4 d-flex align-items-center'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-4 mt-6' style={{ lineHeight: '0.5em' }}>
						<h2 style={{ display: 'flex', gap: '10px' }}>{t('Hoja de vida')}</h2>
					</div>
				</div>
				<div className='row mt-8'>
					<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
						<h3 style={{ display: 'flex', gap: '10px' }}>
							{initValue(resume['usercomplemento']?.['primer_nome']) +
								' ' +
								initValue(resume['usercomplemento']?.['segundo_nome'])}
						</h3>
						<span style={{}}>
							{initValue(resume['usercomplemento']?.['correo'])} {'    '}{' '}
							{initValue(resume['usercomplemento']?.['celular'])}
						</span>
					</div>
					<div className='col d-flex justify-content-end'>
						{/* {currentUser?.user?.role !== 3 && ( */}
						<button
							onClick={handleOpenModal}
							className='btn btn-primary'
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							{t('Contactar')}
						</button>
						{/* )} */}
					</div>
				</div>
				{/* Experiencia */}
				<div className='row mt-10'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Experiencia laboral')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenExperience(!openExperience)}
									aria-label='expand'
									size='small'
								>
									{openExperience ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openExperience} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									{resume['curriculoListId']?.['CurriculoExperienciaLaborals'].map(
										(x: any, i: number) => (
											<div className='mt-10' key={i}>
												<h6>
													{t('Nombre del trabajo')} ({i + 1})
												</h6>
												<ul className='mt-2'>
													<div className='row'>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Tipo de experiencia: ')}</b>
																{t(initValue(x['tpExperienciaLaboral']))}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Sector: ')}</b>
																{t(initValue(x['sector']))}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Cargo: ')}</b>
																{initValue(x['cargo'])}
															</li>
														</div>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Cargo equivalente:  ')}</b>{' '}
																{initValue(x['cargoEquivalente'])}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Nombre de la empresa: ')}</b>
																{initValue(x['nombreEmpresa'])}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Teléfono de la empresa:  ')}</b>
																{initValue(x['telefonoEmpresa'])}
															</li>
														</div>
														<div className='col-sm-4'>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Fecha de ingreso:  ')}</b> {initValue(x['fechaIngresso'])}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Fecha de retiro: ')}</b>
																{initValue(x['fechaRetiro'])}
															</li>
														</div>
													</div>
												</ul>
												<ul>
													<li>
														<b>{t('Funciones y logros: ')}</b>
														{initValue(x['funcionesLogros'])}
													</li>
												</ul>
											</div>
										)
									)}
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>
				{/* Educación */}
				<div className='row'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Educación')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenEducation(!openEducation)}
									aria-label='expand'
									size='small'
								>
									{openEducation ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openEducation} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									<div className='row mt-10'>
										{resume['curriculoListId']?.['CurriculoNivelEducativos'].map(
											(x: any, i: number) => (
												<div className='col-sm-6' key={i}>
													<div className='col-sm-4'>
														<h6>{initValue(x['nivelEducativo'])}</h6>
														<ul className='mt-2'>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Institución: ')}</b>
																{initValue(x['Institucion'])}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Fecha de certificación: ')}</b>
																{initValue(x['fechaCertificacion'])}
															</li>
															<li style={{ margin: '10px 0' }}>
																<b>{t('Estado del curso: ')}</b>
																{t(initValue(x['estadoDelCurso']))}
															</li>
														</ul>
													</div>
												</div>
											)
										)}
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>
				{/* Interés ocupacional */}
				<div className='row'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Interés ocupacional')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenOccupationalInterest(!openOccupationalInterest)}
									aria-label='expand'
									size='small'
								>
									{openOccupationalInterest ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openOccupationalInterest} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									<div className='row'>
										{resume['curriculoListId']?.['Cargos'].map((x: any, i: number) => (
											<span
												key={i}
												className='col-sm-2 badge rounded-pill mt-10'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 30px 15px 30px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '10pt',
													width: 'auto',
												}}
											>
												{t(x['desc_cargo'])}
											</span>
										))}
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>
				{/* Conocimientos */}
				<div className='row'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Conocimientos')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenKnowledge(!openKnowledge)}
									aria-label='expand'
									size='small'
								>
									{openKnowledge ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openKnowledge} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									<div className='row'>
										{resume['curriculoListId']?.['CurriculoConocimientos'].map(
											(x: any, i: number) => (
												<span
													className='col-sm-2 badge rounded-pill mt-10'
													style={{
														boxSizing: 'border-box',
														color: '#153E7B',
														border: '1px solid #153E7B',
														backgroundColor: 'transparent',
														marginRight: '15px',
														marginBottom: '10px',
														padding: '15px 30px 15px 30px',
														textAlign: 'center',
														height: '46px',
														alignContent: 'center',
														fontSize: '11pt',
													}}
													key={i}
												>
													{t(initValue(x['herramientas']))}&nbsp;&nbsp;
													<Rating
														size='medium'
														name='read-only'
														value={setValueOrDefault(x['nivel'], 'number')}
														readOnly
													/>
												</span>
											)
										)}
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>
				{/* Idiomas */}
				<div className='row'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Idiomas')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenLanguages(!openLanguages)}
									aria-label='expand'
									size='small'
								>
									{openLanguages ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openLanguages} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									<div className='row'>
										{resume['curriculoListId']?.['CurriculoIdiomas'].map((x: any, i: number) => (
											<span
												key={i}
												className='col-sm-2 badge rounded-pill mt-10'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 20px 15px 20px',
													textAlign: 'center',
													alignContent: 'center',
													fontSize: '11pt',
													width: 'auto',
												}}
											>
												{t(
													ddlIdioma.find((y, k) => y.id === x['id_idioma']?.toString())?.name || ''
												)}
												&nbsp;&nbsp;
												<Rating
													size='medium'
													name='read-only'
													value={setValueOrDefault(x['nivel'], 'number')}
													readOnly
												/>
											</span>
										))}
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>
			</div>

			{/* MODAL */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{ border: 'none' }}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						{t('Contactar')}{' '}
						{initValue(resume['usercomplemento']?.['primer_nome']) +
							' ' +
							initValue(resume['usercomplemento']?.['segundo_nome'])}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						{t('Escriba un mensaje al candidato')}{' '}
						{initValue(resume['usercomplemento']?.['primer_nome'])}
					</Typography>
					<TextField
						className='mt-5 mb-5'
						fullWidth
						rows={10}
						id='observaciones'
						name='observaciones'
						label={t('Observaciones')}
						value={initValue(companyData['observaciones'])}
						onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
						size='medium'
						multiline
						inputProps={{ MaxLength: 300 }}
						disabled={loading}
						helperText={`${
							companyData['observaciones'] === undefined
								? '0'
								: companyData['observaciones']?.length
						}/${1200}`}
						// helperText={`${funcionesLogros === undefined ? '0' : funcionesLogros?.length}/${2000}`}
					/>

					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-primary'
							onClick={() => changeEnviaEmail()}
							disabled={
								companyData.observaciones && companyData.observaciones.length > 0 && !loading
									? false
									: true
							}
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							{t('Enviar email')}
						</button>
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							{t('Cancelar')}
						</button>
					</div>
				</Box>
			</Modal>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</div>
	);
};

const ResumeComponent = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Hoja de vida')}</PageTitle>
			<ResumePage />
		</>
	);
};

export default ResumeComponent;
