import React from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';

import { isValidFieldRequired, } from "../../../../../util/Index";
import { MESES, STATUS_COURSE, TYPES_COURSE } from "../../../../../constants/constants";
import { useEducation } from "../../../../../hooks/curriculum/useEducation";


export default function SectionEducation({ errors, setFieldValue, values, courseTypes }: any) {
    const { t } = useTranslation();
    const {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        conventionalEducation,
        handleConvetionalEducation,
        isSaving
    } = useEducation({ setFieldValue, values });

    const {
        institute,
        course,
        course_type_id,
        status,
        end_year,
        end_month
    } = valueAdd;


    const getListYears = (): JSX.Element[] => {
        const menuItems: JSX.Element[] = []
        const limitYear = new Date().getFullYear() + 6;
        for (let index = limitYear; index >= 1950; index--) {
            menuItems.push(<MenuItem value={index} key={`course-years-education-${index}`}>{index}</MenuItem>)

        }
        return menuItems.map(menu => menu)
    }

    const formatTypeCourse = (id: any) => {
        const founded: any = courseTypes?.find((v: any) => v.id == id);
        return founded?.[`${t('FIELD_COURSE_TYPE')}`] || '';
    }

    const formatStatusCourse = (id: any) => {
        const founded: any = STATUS_COURSE?.find((v: any) => v.val == id);
        return t(`${founded?.tagI18n}`);
    }
    return (
        <>
            <Box sx={{ mb: 3, mt: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('EDUCATION')}
                </Typography>
            </Box>
            <Grid container direction='row' width={"100%"}>
                <Grid xs={12} md={12} mb={5} pr={2} item>
                    <FormControl required>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t('TRADITIONAL_EDUCATION')}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel
                                value={true}
                                control={
                                    <Radio checked={conventionalEducation != null && conventionalEducation}
                                        onChange={(e) => handleConvetionalEducation(true)}
                                    />
                                }
                                label={t('Sí')}
                            />
                            <FormControlLabel
                                value={false}
                                control={
                                    <Radio
                                        checked={conventionalEducation != null && !conventionalEducation}
                                        onChange={(e) => handleConvetionalEducation(false)}
                                    />
                                }
                                label={t('No')}
                            />

                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {conventionalEducation &&
                <>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        width={'100%'}
                        sx={{
                            backgroundColor: '#1C447F',
                            pt: 2,
                            pl: 2,
                            pb: 2,
                            pr: 2,
                            borderTopLeftRadius: 6,
                            borderTopRightRadius: 6,
                        }}
                        item
                    >
                        <Grid container direction='column' item>
                            <Typography color='white' variant='h5'>
                                {t('Agregar')} {t('Educación')} {editMode ? t(' - modo de edición') : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        width={'100%'}
                        mb={5}
                        sx={{
                            // border: 1,
                            borderBottom: 3,
                            borderColor: '#AFADAD',
                            borderBottomLeftRadius: 6,
                            borderBottomRightRadius: 6,
                            pt: 5,
                            pl: 3,
                            pr: 3,
                            border: `${borderColorEdit}`,
                        }}
                        item
                    >
                        <Grid container direction='row' justifyContent='space-between' width={'100%'} item>
                            <Grid xs={12} md={6} mb={5} pr={2} width={'100%'} item>
                                <TextField
                                    fullWidth
                                    required
                                    id={'instituteEducation'}
                                    name={'institute'}
                                    label={t('INSTUTION_COLLEGE')}
                                    value={institute || ''}
                                    onChange={(e) => setValues({ ...valueAdd, institute: e.target.value })}
                                    error={isSaving && !isValidFieldRequired(institute)}
                                    placeholder={t('INSTUTION_COLLEGE')}
                                    size='medium' />
                            </Grid>
                            <Grid xs={12} md={6} mb={5} pr={2} width={'100%'} item>
                                <TextField
                                    fullWidth
                                    required
                                    id={'course'}
                                    name={'course'}
                                    label={t('NAME_COURSE')}
                                    value={course || ''}
                                    onChange={(e) => setValues({ ...valueAdd, course: e.target.value })}
                                    error={isSaving && !isValidFieldRequired(course)}
                                    placeholder={t('NAME_COURSE')}
                                    size='medium' />
                            </Grid>
                            <Grid direction='row' width={'100%'} mb={5} pr={2} container>
                            <Grid xs={12} md={6} mb={2} pr={2} width={'100%'} item>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={isSaving && !isValidFieldRequired(course_type_id)}
                                >
                                    <InputLabel id={'type'}>{t('GRAU')} *</InputLabel>
                                    <Select
                                        labelId={'type'}
                                        id={'type'}
                                        label={t('GRAU')}
                                        value={course_type_id || ''}
                                        onChange={(e) => setValues({ ...valueAdd, course_type_id: e.target.value })}
                                    >
                                        <MenuItem value=''>{t('GRAU')}</MenuItem>
                                        {courseTypes?.map((item: any) => <MenuItem value={item.id} key={`key-to-test-level-${item.id}`}>{item[`${t('FIELD_COURSE_TYPE')}`]}</MenuItem>)}
                                    </Select>
                                    {isSaving && !isValidFieldRequired(course_type_id) && (
                                        <FormHelperText>
                                            {isSaving && !isValidFieldRequired(course_type_id) ? t('Campo requerido') : ''}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6} mb={2} pr={2} width={'100%'} item>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={isSaving && !isValidFieldRequired(status)}
                                >
                                    <InputLabel id={'status'}>{t('Estado del curso')} *</InputLabel>
                                    <Select
                                        labelId={'status'}
                                        id={'status'}
                                        label={t('Estado del curso')}
                                        value={(status || status == 0) ? status : ''}
                                        onChange={(e) => setValues({ ...valueAdd, status: e.target.value })}
                                    >
                                        <MenuItem value='' key={`key-to-test-level-0`}>{t('Estado del curso')}</MenuItem>
                                        {STATUS_COURSE.map((item) => <MenuItem value={item.val} key={`course-status-${item.tagI18n}`}>{t(item.tagI18n)}</MenuItem>)}
                                    </Select>
                                    {isSaving && !isValidFieldRequired(status) && (
                                        <FormHelperText>
                                            {isSaving && !isValidFieldRequired(status) ? t('Campo requerido') : ''}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            </Grid>
                            {/* <Grid xs={12} md={4} mb={2} pr={2} item>
                                <FormControl
                                    sx={{ minWidth: '100%' }}
                                    size='medium'
                                    error={isSaving && !isValidFieldRequired(end_year)}
                                >
                                    <InputLabel id={'fecha_Retiro'}>{t('YEAR_CONCLUSION')} </InputLabel>
                                    <Select
                                        labelId={'fecha_Retiro_y'}
                                        id={'fecha_Retiro_y'}
                                        label={t('YEAR_CONCLUSION')}
                                        value={end_year || 0}
                                        onChange={(e) => setValues({ ...valueAdd, end_year: e.target.value })}
                                        onBlur={(e) => setValues({ ...valueAdd, end_year: e.target.value })}
                                    >

                                        <MenuItem value={0} key='empty-year'>{t('YEAR_CONCLUSION')}</MenuItem>
                                        {getListYears()}
                                    </Select>
                                    {isSaving && !isValidFieldRequired(end_year) && (
                                        <FormHelperText>
                                            {isSaving && !isValidFieldRequired(end_year) ? t('Campo requerido') : ''}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid> */}
                            <Grid direction='row' width={'100%'} mb={5} pr={2} container>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                    >
                                        <InputLabel id={'fecha_Retiro'}>{t('MONTH')}</InputLabel>
                                        <Select
                                            labelId={'fecha_Retiro_y'}
                                            id={'fecha_Retiro_y'}
                                            label={t('MONTH')}
                                            value={end_month}
                                            onChange={(e) => setValues({ ...valueAdd, end_month: e.target.value })}
                                        >
                                            {

                                            }
                                            <MenuItem value={''}>{t('MONTH')}</MenuItem>
                                            {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                    >
                                        <InputLabel id={'fecha_Retiro'}>{t('YEAR')}</InputLabel>
                                        <Select
                                            labelId={'fecha_Retiro_y'}
                                            id={'fecha_Retiro_y'}
                                            label={t('YEAR')}
                                            value={end_year || 0}
                                            onChange={(e) => setValues({ ...valueAdd, end_year: e.target.value })}
                                            onBlur={(e) => setValues({ ...valueAdd, end_year: e.target.value })}
                                        >
                                            {

                                            }
                                            <MenuItem value={''}>{t('YEAR')}</MenuItem>
                                            {getListYears()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
                            {editMode ? (
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                                        <Button
                                            variant='contained'
                                            onClick={handleSaveEdit}
                                            sx={{ width: 90, background: '#0A4396', mr: 1 }}
                                        >
                                            {t('SAVE')}
                                        </Button>

                                        <Button
                                            variant='contained'
                                            sx={{ width: 90, background: '#6c757d', mr: 1 }}
                                            onClick={handleclear}
                                        >
                                            {t('Cancelar')}
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <Button
                                    // disabled={tieneExperienciaLaboral === '0' || tieneExperienciaLaboral === ''}
                                    variant='contained'
                                    onClick={handleAddExperienciaLaboral}
                                    sx={{ width: 90, background: '#0A4396' }}
                                    disabled={
                                        !institute ||
                                        !course ||
                                        course_type_id === '' ||
                                        course_type_id === undefined ||
                                        status === '' ||
                                        status === undefined ||
                                        end_year === '' ||
                                        end_year === undefined ||
                                        end_month === '' ||
                                        end_month === undefined
                                      }
                                >
                                    {t('Agregar')} 
                                </Button> 
                            )}
                        </Grid>
                    </Grid>
                    {rows?.length > 0 && (
                        <Grid xs={12} item>
                            <Typography color='#2B2B2B' variant='h6'>
                                {t('Agregados')}
                            </Typography>
                            <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
                        </Grid>
                    )}
                    <Grid xs={12} item>
                        {rows?.map((row, i) => (
                            <Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    width={'100%'}
                                    sx={{
                                        backgroundColor: '#1C447F',
                                        pt: 2,
                                        pl: 2,
                                        pb: 2,
                                        pr: 2,
                                        borderTopLeftRadius: 6,
                                        borderTopRightRadius: 6,
                                    }}
                                    item
                                >
                                    <Typography color='white' variant='h6'>
                                        {' '}
                                        {t(row.course || '') || ''}
                                    </Typography>
                                    <Box display='flex' justifyContent='end' alignItems='center'>
                                        <div>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                <button
                                                    className='btn btn-icon btn-active-color-secondary btn-sm me-3'
                                                    style={{ background: '#C4D2E8' }}
                                                    onClick={() => handleEdit(row)}
                                                    type="button"
                                                >
                                                    <EditIcon />
                                                </button>

                                                <button
                                                    className='btn btn-icon btn-active-color-secondary btn-sm'
                                                    onClick={() => handleRemoveExperienciaLaboral(row.id || 0)}
                                                    style={{ background: '#DD1342' }}
                                                    type="button"
                                                >
                                                    <DeleteOutlinedIcon style={{ color: 'white' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    width={'100%'}
                                    mb={5}
                                    sx={{
                                        border: 1,
                                        borderBottom: 3,
                                        borderColor: '#AFADAD',
                                        borderBottomLeftRadius: 6,
                                        borderBottomRightRadius: 6,
                                        pt: 5,
                                        pl: 3,
                                        pr: 3,
                                    }}
                                    item
                                >

                                    <Grid xs={12} md={3} mb={5} mr={5} item>
                                        <Typography>{t('Institución: ') + (row.institute || '')}</Typography>
                                        <Typography>{t('COURSE') + ': ' + (row.course || '')}</Typography>
                                        <Typography>{t('TYPE') + ': ' + (formatTypeCourse(row.course_type_id) || '')}</Typography>
                                    </Grid>
                                    <Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
                                    <Grid xs={12} md={2} mb={5} mr={2} item>
                                        <Typography>{t('Status: ') + (formatStatusCourse(row.status) || '')}</Typography>
                                        <Typography>{t('YEAR_CONCLUSION') + ": " + (row.end_year || '')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {t(alertMsg)}
                </Alert>
            </Snackbar>
        </>
    )
}