import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionVoluntaryType = {
    id?: number;
    institution?: string;
    name?: string | undefined | null;
    activity_performed?: string | undefined | boolean;
    start_date?: number | string | undefined | null;
    end_date?: number | string | undefined | null;
}

export const useVoluntary = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { t } = useTranslation();
    const { volunteering } = values;
    const [valueAdd, setValueAdd] = useState<SectionVoluntaryType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);

    const [openRowEdit, setOpenRowEdit] = useState<number>(0); const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionVoluntaryType[]>(volunteering || []);
    const setValues = (val: SectionVoluntaryType) => {
        setValueAdd(val);
    }

    useMemo(() => {
        if (volunteering?.length > 0) {
            setRows(volunteering)
        }

    }, [volunteering])

    const {
        institution,
        name,
        activity_performed,
        start_date,
        end_date
    } = valueAdd;


    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
    };

    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('volunteering', rowsAdd);
    }


    const handleSaveEdit = () => {
        if (editMode) {
            let item = rows?.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows?.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows?.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit || parseInt(`${m}${s}`);


                itemsToSave.push({
                    id: idRandom,
                    institution: institution as string,
                    name: name,
                    activity_performed: activity_performed,
                    start_date: start_date,
                    end_date: end_date,
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows?.length + 1,
                    institution: institution as string,
                    name: name,
                    activity_performed: activity_performed,
                    start_date: start_date,
                    end_date: end_date,

                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Voluntariado Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);

        saveToForm(list);
    };


    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];

        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }
        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        // window.scrollTo(0, 170);
        const doc = document.getElementById("nameVolunteering");
        doc?.focus({
            preventScroll: false
        });
        setValueAdd({
            id: item.id,
            institution: item.institution as string,
            name: item.name,
            activity_performed: item.activity_performed,
            start_date: item.start_date,
            end_date: item.end_date,
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        setFieldValue,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows
    }

}